import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from './shared/responsive/responsive.service';
import { Destroyable } from './util/destroyable';
import { TranslateService } from '@ngx-translate/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { AuthStateService } from './core/auth/auth-state.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { fader } from './route-animations';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { LoadingService } from './core/loading/loading.service';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { TranslateLanguageService } from './translation/translate-language.service';
import { UserDto } from '@generated/auth-api';

export const LOADING_KEY__NAVIGATE = 'CandidateExamsView.ExamsRequest';

function isMenuVisible(user?: UserDto): boolean {
  if (!user) {
    return false;
  }
  // If we do not login as teacher/org we can show the menu once logged in
  if (user.context !== UserDto.ContextEnum.School) {
    return true;
  }
  // Otherwise we need to check that an org is also selected
  return !!user.activeOrg;
}

@Component({
  selector: 'sx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // slider,
    fader,
  ],
})
export class AppComponent extends Destroyable implements OnInit {
  visible = false;
  mode = 'side';
  isBelowDesktop: boolean;
  showMenu: boolean;

  constructor(
    private responsive: ResponsiveService,
    public translate: TranslateService,
    public translateLanguage: TranslateLanguageService,
    private authState: AuthStateService,
    private router: Router,
    private swUpdate: SwUpdate,
    private localStorage: LocalStorageService,
    private loading: LoadingService,
    private dialogs: GenericDialogs,
  ) {
    super();

    translate.addLangs(['en', 'de', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    const userLang: string = this.localStorage.getAs('lang') || browserLang;

    translateLanguage.lang = userLang.match(/en|de|fr/) ? userLang : translate.defaultLang;
  }

  ngOnInit(): void {
    this.responsive.isBelowDesktop$
      .pipe(this.takeUntilDestroyed())
      .subscribe(this.changeNav.bind(this));

    this.authState.state$
      .pipe(
        map((state) => state.user),
        map(isMenuVisible),
      )
      .subscribe((show) => (this.showMenu = show));

    this.loading.setupLoadingDialog();
    this.closeNavOnNavigate();
    this.showUpdateNotificationIfAvailable();

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading.start(LOADING_KEY__NAVIGATE);
          return;
        }

        // Hiding the menu for the payment route
        if (event instanceof NavigationEnd && event.url?.startsWith('/payment')) {
          this.showMenu = false;
        }

        // Else navigation has ended, so `stop()` the loading indicator
        this.loading.stop(LOADING_KEY__NAVIGATE);
      });
  }

  changeNav(isBelowDesktop: boolean) {
    this.isBelowDesktop = isBelowDesktop;

    if (isBelowDesktop) {
      this.mode = 'over';
      this.visible = false;
    } else {
      this.mode = 'side';
      this.visible = true;
    }
  }

  onToggleNav() {
    this.visible = !this.visible;
  }

  onNavToggled(isVisible: boolean) {
    this.visible = isVisible;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  private closeNavOnNavigate() {
    // close sidenav on routing (mobile only)
    this.router.events.subscribe(() => {
      if (!this.isBelowDesktop) {
        return;
      }
      this.visible = false;
    });
  }

  private showUpdateNotificationIfAvailable() {
    // show popup if a newer version of the PWA is available
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          switchMap(() =>
            this.dialogs.confirm(
              'A new version is available!',
              'A new version of Swiss Exams Access is available. Do you want to load the new version?',
            ),
          ),
          filter((value) => !!value),
          tap(() => window.location.reload()),
        )
        .subscribe();
    }
  }
}
