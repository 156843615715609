import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CANDIDATE_LINGUASKILL_FEATURE } from './candidate-linguaskill.feature';
import { CandidateLinguaskillState } from './candidate-linguaskill.state';

export const selectCandidateLinguaSkillsFeature = createFeatureSelector<CandidateLinguaskillState>(
  CANDIDATE_LINGUASKILL_FEATURE,
);

export const selectCandidateHasAssessments = createSelector(
  selectCandidateLinguaSkillsFeature,
  (feature) => !!feature.hasAssessments,
);
