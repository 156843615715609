import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * This service should be injected on component level, if used.
 */
@Injectable()
export class InPlaceEditorService {
  private editingSubject = new BehaviorSubject<boolean>(false);
  readonly editing$ = this.editingSubject.asObservable();

  get editing() {
    return this.editingSubject.getValue();
  }

  set editing(editing: boolean) {
    this.editingSubject.next(editing);
  }
}
