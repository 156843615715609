/**
 * sx-ms-skills-assessment-api
 * Swiss Exams - Microservices - Skills Assessment Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetAssessmentForCandidateDto { 
    state: GetAssessmentForCandidateDto.StateEnum;
}
export namespace GetAssessmentForCandidateDto {
    export type StateEnum = 'Active' | 'Past';
    export const StateEnum = {
        Active: 'Active' as StateEnum,
        Past: 'Past' as StateEnum
    };
}


