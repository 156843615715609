import { Component, Input, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sx-router-link-button',
  templateUrl: './router-link-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RouterLinkButtonComponent implements OnInit {
  @HostBinding('class') class = 'sx-action-button';

  @Input() label: string;
  @Input() type = 'button';
  @Input() small = false;

  @Input() link: string[];

  ngOnInit() {
    if (this.small) {
      this.class += ' small';
    }
  }
}
