import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'sx-action-button',
  templateUrl: './action-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ActionButtonComponent implements OnInit {
  @HostBinding('class') class = 'sx-action-button';

  @Input() label: string;
  @Input() type = 'button';
  @Input() small = false;
  @Input() icon = 'chevron_right';
  @Input() inverse = false;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() iconColor = 'primary';
  @Input() spinnerColor: 'primary' | 'white' = 'primary';

  @Output() action = new EventEmitter<any>();

  ngOnInit() {
    if (this.small) {
      this.class += ' small';
    }
    if (this.inverse) {
      this.class += ' inverse';
    }
  }

  onClick(event) {
    this.action.emit(event);
  }
}
