<mat-card appearance="raised">
  <mat-card-title>
    <h1>{{'Payment successful'|translate}}</h1>
  </mat-card-title>
  <mat-card-subtitle>{{ "Thank you for your payment"| translate }}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <p>{{label}}</p>
    <div>{{"Reference"|translate}}: {{irn}}</div>
  </mat-card-content>
  <ng-template #loading>
    <mat-card-content>
      <p>{{"Payment processing"|translate }}</p>
    </mat-card-content>
  </ng-template>
</mat-card>
