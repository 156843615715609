/**
 * sx-ms-school-api
 * Swiss Exams - Microservices - school Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentDto { 
    method: PaymentDto.MethodEnum;
    eroCode?: string;
    collectiveInvoiceCode?: string;
    eroLatePaymentMethod?: PaymentDto.EroLatePaymentMethodEnum;
}
export namespace PaymentDto {
    export type MethodEnum = 'Credit Card / Postcard' | 'Collective Invoice' | 'Online Invoice' | 'Exam Retake Option';
    export const MethodEnum = {
        CreditCardPostcard: 'Credit Card / Postcard' as MethodEnum,
        CollectiveInvoice: 'Collective Invoice' as MethodEnum,
        OnlineInvoice: 'Online Invoice' as MethodEnum,
        ExamRetakeOption: 'Exam Retake Option' as MethodEnum
    };
    export type EroLatePaymentMethodEnum = 'Credit Card / Postcard' | 'Online Invoice';
    export const EroLatePaymentMethodEnum = {
        CreditCardPostcard: 'Credit Card / Postcard' as EroLatePaymentMethodEnum,
        OnlineInvoice: 'Online Invoice' as EroLatePaymentMethodEnum
    };
}


