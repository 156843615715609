import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sx-key-value',
  template: `
    <label class="key-value__key">{{ key }}</label>
    <div class="key-value__value">
      <ng-content></ng-content>
    </div>
  `,
})
export class KeyValueComponent {
  @Input()
  key: string;

  @Input()
  value: string;

  @Input()
  set alignTop(alignTop: boolean) {
    this.cssClass = alignTop ? 'key-value key-value--top-aligned' : 'key-value';
  }

  @HostBinding('class')
  private cssClass = 'key-value';
}
