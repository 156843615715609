import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthStateService } from './auth/auth-state.service';
import { AccessTokenDto, AuthApi } from '@generated/auth-api';
import { Router } from '@angular/router';
import { NotificationService } from '@shared/providers/notification.service';

@Injectable()
export class JwtRefreshInterceptor implements HttpInterceptor {
  constructor(
    private authStateService: AuthStateService,
    private api: AuthApi,
    private router: Router,
    private notification: NotificationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          (error.error === 'JWT Token Expired' || error.error.message === 'JWT Token Expired')
        ) {
          return this.refresh(req, next);
        }
        return throwError(error);
      }),
    );
  }

  private refresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { token } = this.authStateService.state;
    // Changed the name of refresh token because swagger code gen creates refreshToken out of refresh_token.
    // Now I changed the name, so the swagger api matches the data we get, but in case there might
    // be someone with an old, not updated version, we also retry the old refresh token if the new
    // is not present.
    const refreshToken = token.refreshToken || (token as any).refresh_token;
    return this.api.refreshJwtToken({ token: refreshToken }).pipe(
      catchError((error) => {
        const { state } = this.authStateService;
        this.notification.error('Your session has expired. Please login again.');
        this.router.navigate([
          '/login/' + (state && state.user && state.user.context) || 'candidate',
        ]);
        this.authStateService.onLogout();
        return throwError(error);
      }),
      tap((newToken: AccessTokenDto) => this.authStateService.onTokenAvailable(newToken)),
      switchMap((newToken: AccessTokenDto) =>
        next.handle(
          req.clone({
            setHeaders: {
              Authorization: `Bearer ${newToken.token}`,
            },
          }),
        ),
      ),
    );
  }
}
