import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sx-home-view',
  template: `
    <mat-card appearance="raised" class="public-card">
      <mat-card-header>
        <mat-card-title>
          <mat-icon color="primary">vpn_key</mat-icon>
          <span>{{ 'Welcome to Swiss Exams - Access' | translate }}</span>
        </mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-actions class="public-card__actions">
        <sx-router-link-button
          [label]="'Candidate Access' | translate"
          [type]="'button'"
          [link]="'/login/candidate'"
          class="public-card__action-button"
        ></sx-router-link-button>
        <sx-router-link-button
          [label]="'Organization and School Access' | translate"
          [type]="'button'"
          [link]="'/login/school'"
          class="public-card__action-button"
        ></sx-router-link-button>
        <sx-router-link-button
          [label]="'Staff Access Title' | translate"
          [type]="'button'"
          [link]="'/login/staff'"
          class="public-card__action-button"
        ></sx-router-link-button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class HomeViewComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Swiss Exams Access | All exams, payment status and more');
  }
}
