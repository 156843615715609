import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserDto } from '@generated/auth-api';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Destroyable } from 'src/app/util/destroyable';
import { AuthStateService } from '../auth/auth-state.service';
import { selectCandidateHasAssessments, selectCandidateHasExams } from '../store';
import { NavItem } from './nav-item';
import { NavService } from './nav.service';

@Component({
  selector: 'sx-nav-view',
  templateUrl: 'nav.view.html',
  styleUrls: ['nav.view.scss'],
})
export class NavViewComponent extends Destroyable implements OnInit {
  user$: Observable<UserDto>;

  context: UserDto.ContextEnum;
  contextTitle = '';

  items$: Observable<NavItem[]>;
  isSchool$: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private authState: AuthStateService,
    private store: Store,
    private navService: NavService,
    private cdref: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setUserObserver();
    this.setSchoolObserver();
    this.setNavItemsObserver();
  }

  private setUserObserver(): void {
    this.user$ = this.authState.state$.pipe(map((state) => state.user));
  }

  private setSchoolObserver(): void {
    this.isSchool$ = this.user$.pipe(map((user) => !!user?.activeOrg?.schoolCrmId));
  }

  private setNavItemsObserver(): void {
    const langChange$ = this.translate.onLangChange.pipe(
      startWith({ lang: this.translate.currentLang }),
    );
    const candidateHasAssessments$ = this.store.pipe(select(selectCandidateHasAssessments));
    const candidateHasExams$ = this.store.pipe(select(selectCandidateHasExams));

    this.items$ = combineLatest([
      this.user$,
      candidateHasAssessments$,
      candidateHasExams$,
      langChange$,
    ]).pipe(
      tap(([user]) => this.setContext(user)),
      map(([user, candidateHasAssessments, candidateHasExams]) =>
        this.navService.createNavigation(user, candidateHasAssessments, candidateHasExams),
      ),
    );
  }

  private setContext(user: UserDto): void {
    this.context = user.context;
    this.setContextTitle(user);

    this.cdref.detectChanges();
  }

  private setContextTitle = (user: UserDto): void => {
    const { Staff, School } = UserDto.ContextEnum;

    if (!user) {
      this.contextTitle = '';
    }

    if (user.context === School) {
      this.contextTitle = user.activeOrg?.name || '';
    }

    if (user.context === Staff) {
      this.contextTitle = this.translate.instant('Staff Access Title');
    }
  };
}
