import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ContactDto, ContactApi } from '@generated/candidate-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactResolver {
  constructor(private contactApi: ContactApi) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContactDto> {
    return this.contactApi.getContact();
  }
}
