import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  title: string;
  message: string;
  footer?: string;
}

@Component({
  selector: 'sx-confirmation-dialog',
  template: `
    <sx-card-layout-view class="dialog" [label]="data.title || 'Confirm'">
      <div mat-dialog-content>
        <p [innerHTML]="data.message | translate"></p>
      </div>
      <mat-divider class="dialog__divider"></mat-divider>
      <div class="dialog__actions">
        <ng-template [ngIf]="!!data.footer">
          <div class="dialog__actions__message">
            {{ data.footer }}
          </div>
          <div class="dialog__actions__spacer"></div>
        </ng-template>
        <button mat-button (click)="onNoClick()">{{ 'No' | translate }}</button>
        <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
          {{ 'Yes' | translate }}
        </button>
      </div>
    </sx-card-layout-view>
  `,
})
export class ConfirmationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogData,
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}
