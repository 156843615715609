import { Component } from '@angular/core';
import { AttachmentViewFacade } from './attachment.view-facade';

@Component({
  selector: 'sx-attachment-view',
  template: `
    <h1>{{ 'Attachment Download' | translate }}</h1>
    <div>
      <form [formGroup]="facade.form">
        <mat-form-field>
          <mat-label>{{ 'Password' | translate }}</mat-label>
          <input matInput formControlName="password" type="password" />
        </mat-form-field>
        <button style="margin-left: 16px" mat-button color="primary" (click)="onDownload()">
          Download
        </button>
      </form>
    </div>
  `,
  providers: [AttachmentViewFacade],
})
export class AttachmentViewComponent {
  constructor(public facade: AttachmentViewFacade) {}

  onDownload() {
    this.facade.download();
  }
}
