<mat-card appearance="raised">
  <mat-card-header>
    <mat-card-title class="title" mat-dialog-title>
      <mat-icon class="title__icon" color="primary">{{ matIconName }}</mat-icon>
      <span class="title__text">{{ titleLabel | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="!!subTitle && !(isPhone && omitSubTitleOnMobile)">
      {{ subTitle | translate }}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="wrapper" mat-dialog-content [style.maxWidth]="maxWidth">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions mat-dialog-actions>
    <ng-content select="[dialog-actions]"></ng-content>
  </mat-card-actions>
</mat-card>
