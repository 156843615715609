import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { INFORMAL_MAPPING } from './languages';

@Injectable({
  providedIn: 'root',
})
export class TranslateLanguageService {
  private langSubject = new BehaviorSubject<string>('en');
  private readonly lang$ = this.langSubject.asObservable();
  private readonly formalSubject = new BehaviorSubject<boolean>(false);
  private readonly formal$ = this.formalSubject.asObservable();

  set lang(lang: string) {
    if (this.langSubject.getValue() !== lang) {
      this.langSubject.next(lang);
    }
  }

  set formal(formal: boolean) {
    if (this.formalSubject.getValue() !== formal) {
      this.formalSubject.next(formal);
    }
  }

  constructor(private translate: TranslateService) {
    combineLatest([this.lang$, this.formal$]).subscribe(([lang, formal]) => {
      const langToSet = formal ? lang : INFORMAL_MAPPING[lang] || lang;
      if (this.translate.currentLang !== langToSet) {
        this.translate.use(langToSet);
      }
    });
  }
}
