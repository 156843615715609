import { CandidateExamsEffects } from './candidate-exams.effects';

export * from './candidate-exams.actions';
export * from './candidate-exams.effects';
export * from './candidate-exams.feature';
export * from './candidate-exams.reducer';
export * from './candidate-exams.selectors';
export * from './candidate-exams.state';

export const candidateExamsEffects = [CandidateExamsEffects];
