import { Component, Input } from '@angular/core';
import { AddressDto } from '@generated/candidate-api';
import { UntypedFormGroup } from '@angular/forms';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../util/validation/validation-error-details';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sx-contact-details-address',
  template: `
    <h2 *ngIf="label" class="section-heading section-heading--padding">{{ label }}</h2>
    <div [formGroup]="addressForm" *ngIf="addressForm">
      <sx-key-value [key]="'Street' | translate">
        <sx-in-place-editor>
          {{ line1 }}
          <div class="editor">
            <mat-form-field>
              <input matInput type="text" formControlName="line1" />
              <mat-error *ngIf="getErrorDetails('line1', 'Street') as details">
                {{ details.message | translate: details.params }}
              </mat-error>
            </mat-form-field>
          </div>
        </sx-in-place-editor>
      </sx-key-value>
      <mat-divider></mat-divider>
      <sx-key-value [key]="'Additional Address Line' | translate">
        <sx-in-place-editor>
          {{ line2 }}
          <div class="editor">
            <mat-form-field>
              <input matInput type="text" formControlName="line2" />
              <mat-error *ngIf="getErrorDetails('line2', 'Additional Street Line') as details">
                {{ details.message | translate: details.params }}
              </mat-error>
            </mat-form-field>
          </div>
        </sx-in-place-editor>
      </sx-key-value>
      <mat-divider></mat-divider>
      <sx-key-value [key]="'ZIP / City' | translate">
        <sx-in-place-editor>
          {{ zipCity }}
          <div class="editor zipCity">
            <div class="postalCode">
              <mat-form-field>
                <input matInput type="text" formControlName="postalCode" />
                <mat-error *ngIf="getErrorDetails('postalCode', 'Postal Code') as details">
                  {{ details.message | translate: details.params }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="city">
              <mat-form-field>
                <input matInput type="text" formControlName="city" />
                <mat-error *ngIf="getErrorDetails('city', 'City') as details">
                  {{ details.message | translate: details.params }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </sx-in-place-editor>
      </sx-key-value>
      <mat-divider></mat-divider>
      <sx-key-value [key]="'Country' | translate">
        <sx-in-place-editor>
          {{ country ? ('Countries.' + country | translate) : '' }}
          <div class="editor">
            <mat-form-field>
              <mat-select formControlName="country">
                <mat-option *ngFor="let sc of countries" [value]="sc">
                  {{ 'Countries.' + sc | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="getErrorDetails('country', 'Country') as details">
                {{ details.message | translate: details.params }}
              </mat-error>
            </mat-form-field>
          </div>
        </sx-in-place-editor>
      </sx-key-value>
      <mat-divider></mat-divider>
    </div>
  `,
})
export class ContactDetailsAddressComponent {
  @Input()
  label: string;

  addressForm: UntypedFormGroup;
  getErrorDetails: ValidationErrorDetailsGetter = () => undefined;

  @Input('addressForm')
  set _addressForm(form: UntypedFormGroup) {
    this.addressForm = form;
    this.getErrorDetails = validationErrorDetails(form, this.translate);
  }

  _address: AddressDto;
  addressLines = '';
  line1;
  line2;
  zipCity = '';
  postalCode: string;
  city: string;
  country: string;

  countries = ['CH', 'DE', 'AT', 'FL'];

  @Input()
  set address(address: AddressDto) {
    this._address = address;
    if (!address) {
      this.addressLines = '';
      this.zipCity = '';
      return;
    }
    this.line1 = address.line1;
    this.line2 = address.line2;
    this.postalCode = address.postalCode;
    this.city = address.city;
    this.country = address.country;
    this.addressLines = [address.line1].filter((v) => !!v).join('\n');
    this.zipCity = [address.postalCode, address.city].filter((v) => !!v).join(' ');
  }

  constructor(private translate: TranslateService) {}
}
