import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { AttachmentsApi } from '@generated/candidate-api';
import { catchError, pluck, switchMap, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Destroyable } from '../../util/destroyable';
import { NotificationService } from '@shared/providers/notification.service';
import { throwError } from 'rxjs';

const PASSWORD_STORAGE_KEY = 'Access.Attachments.DLP';

@Injectable()
export class AttachmentViewFacade extends Destroyable {
  form = new UntypedFormGroup({
    password: new UntypedFormControl('', Validators.required),
  });

  attachmentId: string;

  constructor(
    private storage: LocalStorageService,
    private route: ActivatedRoute,
    private attachments: AttachmentsApi,
    private notifications: NotificationService,
  ) {
    super();
    const storedPassword = this.storage.getAs<string>(PASSWORD_STORAGE_KEY);
    if (storedPassword) {
      this.form.get('password').setValue(storedPassword);
    }
    route.params
      .pipe(pluck('attachmentId'), this.takeUntilDestroyed())
      .subscribe((attachmentId) => {
        this.attachmentId = attachmentId;
        if (storedPassword) {
          this.download();
        }
      });
  }

  download() {
    if (!this.attachmentId) {
      return;
    }
    const { password } = this.form.value;
    this.attachments
      .getAttachmentInfo(this.attachmentId, password)
      .pipe(
        tap(() => this.storage.put(PASSWORD_STORAGE_KEY, password)),
        catchError((error) => {
          this.notifications.error('Failed to download file');
          return throwError(error);
        }),
        switchMap(() => this.attachments.downloadAttachment(this.attachmentId, password)),
      )
      .subscribe();
  }
}
