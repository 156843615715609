import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserDto } from '@generated/auth-api';
import { AnnouncementDto } from '@generated/sa-api/model/announcementDto';
import { LanguageEnum } from '../../translation/languages';

const ANNOUNCEMENT_BANNER_CSS_PREFIX = 'announcement-banner--';

type LanguageSpecificAnnouncement = {
  message: string;
  link: string;
  linkText: string;
};

type AnnouncementSlot = 'linguaskill_dialog';
@Component({
  selector: 'sx-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnouncementBannerComponent {
  @Input() currentLanguage = LanguageEnum.EN;

  @Input()
  announcements: AnnouncementDto[] = [];

  @Input()
  slot: AnnouncementSlot = null;

  @Input()
  context: UserDto.ContextEnum = null;

  canDisplay(announcement: AnnouncementDto): boolean {
    return (
      this.isValidTimeFrame(announcement) &&
      this.isValidSlot(announcement) &&
      this.isValidContext(announcement) &&
      this.hasInMinimumAnEnglishMessage(announcement)
    );
  }

  showIcon(announcement: AnnouncementDto): boolean {
    return !!announcement.icon;
  }

  showLink(announcement: AnnouncementDto): boolean {
    return announcement.linkEn && announcement.linkEn !== '';
  }

  getAdditionalCssClasses(announcement: AnnouncementDto): string {
    return `${ANNOUNCEMENT_BANNER_CSS_PREFIX}${announcement.type}`;
  }

  getLanguageSpecificAnnouncement(announcement: AnnouncementDto): LanguageSpecificAnnouncement {
    const languageSpecificAnnouncement: LanguageSpecificAnnouncement = {
      message: '',
      link: '',
      linkText: '',
    };

    switch (this.currentLanguage.toLowerCase()) {
      case LanguageEnum.EN:
        languageSpecificAnnouncement.message = announcement?.messageEn;
        languageSpecificAnnouncement.link = announcement?.linkEn;
        languageSpecificAnnouncement.linkText = announcement?.linkTextEn;
        break;

      case LanguageEnum.DE:
        languageSpecificAnnouncement.message = announcement?.messageDe || announcement?.messageEn;
        languageSpecificAnnouncement.link = announcement?.linkDe || announcement?.linkEn;
        languageSpecificAnnouncement.linkText =
          announcement?.linkTextDe || announcement?.linkTextEn;
        break;

      case LanguageEnum.DE_INFORMAL:
        languageSpecificAnnouncement.message =
          announcement?.messageDeInformal || announcement?.messageEn;
        languageSpecificAnnouncement.link = announcement?.linkDeInformal || announcement?.linkEn;
        languageSpecificAnnouncement.linkText =
          announcement?.linkTextDeInformal || announcement?.linkTextEn;
        break;

      case LanguageEnum.FR:
        languageSpecificAnnouncement.message = announcement?.messageFr || announcement?.messageEn;
        languageSpecificAnnouncement.link = announcement?.linkFr || announcement?.linkEn;
        languageSpecificAnnouncement.linkText =
          announcement?.linkTextFr || announcement?.linkTextEn;
        break;

      default:
        languageSpecificAnnouncement.message = announcement?.messageEn;
        languageSpecificAnnouncement.link = announcement?.linkEn;
        languageSpecificAnnouncement.linkText = announcement?.linkTextEn;
        break;
    }

    return languageSpecificAnnouncement;
  }

  private hasInMinimumAnEnglishMessage(announcement: AnnouncementDto): boolean {
    return announcement.messageEn && announcement.messageEn !== '';
  }

  private isValidSlot(announcement: AnnouncementDto): boolean {
    return this.slot && this.slot.toLowerCase() === announcement.slot.toLowerCase();
  }

  private isValidContext(announcement: AnnouncementDto): boolean {
    return this.context && this.context.toLowerCase() === announcement?.context.toLowerCase();
  }

  private isValidTimeFrame(announcement: AnnouncementDto): boolean {
    const now = new Date();
    const displayFrom = new Date(announcement?.displayFrom);
    const displayTo = new Date(announcement?.displayTo);

    return announcement.active && displayTo > displayFrom && displayFrom <= now && now <= displayTo;
  }
}
