import { ALL_PAYMENT_METHODS, Tenant, TenantIdentifier } from '@shared/tenant/tenant';
import { PaymentDto } from '@generated/school-api/model/paymentDto';

export const GOETHE_TENANT: Tenant = {
  identifier: TenantIdentifier.Goethe,
  supportedCountries: ['ch', 'de', 'at', 'fl'],
  bulkRegistrationPaymentMethods: ALL_PAYMENT_METHODS.filter(
    (m) => m !== PaymentDto.MethodEnum.CreditCardPostcard,
  ),
  requiresPlaceOfBirth: true,
  termsAndConditions: [
    {
      label: 'I have read and understood the Terms and Conditions.',
      url: 'https://swiss-exams.ch/sites/default/files/goethe_allgemeine_geschaeftsbedingungen.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I confirm that I am in agreement that my photo is taken on the day of my Speaking Test for security reasons.',
      url: 'https://goethe-pruefungen.swiss-exams.ch/de/allgemeine-geschaeftsbedingungen-pruefungsordnungen-und-durchfuehrungsbestimmungen',
      openInNewTab: true,
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test).',
      url: 'https://goethe-pruefungen.swiss-exams.ch/de/wichtig-ausweisbestimmungen',
      openInNewTab: true,
    },
  ],
  logo: {
    src: '../../../assets/goethe/goethe-logo.jpg',
    alt: 'Goethe Logo',
  },
};
