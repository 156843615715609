import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@shared/providers/validation.service';

export interface CommentDialogData {
  title: string;
  message?: string;
}

export interface CommentDialogResult {
  comment: string;
}

@Component({
  selector: 'sx-comment-dialog',
  template: `
    <sx-card-layout-view class="dialog" [label]="data.title">
      <div mat-dialog-content>
        <form [formGroup]="form">
          <p
            *ngIf="data.message"
            class="text margin-bottom--double"
            [innerHTML]="data.message | translate"
          ></p>
          <div>
            <mat-form-field class="stretch">
              <mat-label>{{ 'Comment' | translate }}</mat-label>
              <textarea
                matInput
                matTextareaAutosize
                name="comment"
                formControlName="comment"
              ></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
      <mat-divider class="dialog__divider"></mat-divider>
      <div class="dialog__actions">
        <button mat-button (click)="onCancel()">{{ 'Cancel' | translate }}</button>
        <button mat-button (click)="onOK()">{{ 'OK' | translate }}</button>
      </div>
    </sx-card-layout-view>
  `,
})
export class CommentDialogComponent {
  form = new UntypedFormGroup({
    comment: new UntypedFormControl('', Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CommentDialogData,
    private dialogRef: MatDialogRef<CommentDialogComponent>,
    private validation: ValidationService,
  ) {}

  onOK() {
    this.validation.validateAndThen(this.form, (value) => this.dialogRef.close(value));
  }

  onCancel() {
    this.dialogRef.close();
  }
}
