import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetAssessmentForCandidateDto,
  SaAssessmentGroupDto,
  SharedLinkDto,
  SignedCandidateDto,
  SkillsAssessmentApi,
} from '@generated/sa-api';
import { AnnouncementDto } from '@generated/sa-api/model/announcementDto';

@Injectable({
  providedIn: 'root',
})
export class LinguaskillService {
  constructor(private api: SkillsAssessmentApi) {}

  onVoucherSend(candidate: SignedCandidateDto): Observable<boolean> {
    return this.api.sendEmailToCandidate(candidate);
  }

  getPastAssessments(): Observable<SaAssessmentGroupDto[]> {
    return this.api.getAssessmentsForCandidate({
      state: GetAssessmentForCandidateDto.StateEnum.Past,
    });
  }

  recreateSharedLink(sharedLinkDto: SharedLinkDto) {
    return this.createNewSharedLink(sharedLinkDto);
  }

  createNewSharedLink(sharedLinkDto: SharedLinkDto) {
    return this.api.createSAExaminationResultSharingLink(sharedLinkDto);
  }

  deleteSharedLink(sharedLinkDto: SharedLinkDto) {
    return this.api.deleteSAExaminationResultSharingLink(sharedLinkDto);
  }

  getSharedLinks(sharedLinkDto: SharedLinkDto) {
    return this.api.getSAExaminationResultSharingLinks(sharedLinkDto);
  }

  getAllActiveAnnouncementsWithDueDateNow(): Observable<AnnouncementDto[]> {
    return this.api.getAllActiveAnnouncementsWithDueDateNow();
  }
}
