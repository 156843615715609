export const LANGUAGES = ['en', 'de', 'fr'];

export const INFORMAL_MAPPING = {
  en: 'en',
  de: 'de-informal',
  fr: 'fr',
};

export const FORMAL_MAPPING = {
  en: 'en',
  de: 'de',
  'de-informal': 'de',
  fr: 'fr',
};

export enum LanguageEnum {
  EN = 'en',
  DE = 'de',
  DE_INFORMAL = 'de-informal',
  FR = 'fr',
}
