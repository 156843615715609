import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { UserDto } from '@generated/auth-api';
import { AuthStateService } from 'src/app/core/auth/auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class StaffGuard {
  constructor(
    private authServie: AuthStateService,
    private router: Router,
  ) {}

  canActivate(): UrlTree | boolean {
    const { user } = this.authServie.state;
    const { StaffUserBasicLogin } = UserDto.RightsEnum;

    if (!user) {
      return this.router.parseUrl('/');
    }

    if (!user?.rights?.includes(StaffUserBasicLogin)) {
      return this.router.parseUrl('/');
    }

    return true;
  }
}
