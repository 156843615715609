import { Pipe, PipeTransform } from '@angular/core';

/**
 * This is a helper pipe that is currently used together
 * with translate. In the validation messages we sometimes
 * get a value that we need to translate, but sometimes this
 * value is an object which cannot be translated. In that case
 * there will be an error thrown in the console.
 * We can use this pipe to transform all none string values
 * into a placeholder string which can be handled by the translate
 * service.
 */
@Pipe({
  name: 'toStringIfNone',
  pure: true,
})
export class ToStringIfNonePipe implements PipeTransform {
  transform(value: any, defaultString = '-'): any {
    return typeof value === 'string' ? value : defaultString;
  }
}
