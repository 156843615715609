<sx-public-frame-view></sx-public-frame-view>
<!-- TODO: Why do we need these here? -->

<div class="container">
  <mat-card appearance="raised" class="public-card mat-card">
    <mat-card-header>
      <mat-card-title>
        <mat-icon color="primary">vpn_key</mat-icon>
        <span>{{ 'Select Organization' | translate }}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="public-card__content">
      <form [formGroup]="form" (ngSubmit)="onSelectOrg()" class="public-card__form">
        <mat-form-field>
          <mat-label>{{ 'Organization' | translate }}</mat-label>
          <mat-select formControlName="org" #orgSelector>
            <mat-option *ngFor="let item of orgs" [value]="item.crmId">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="validate('org', 'Organization' || 'Scope') as details">
            {{ details.message | translate: details.params }}
          </mat-error>
        </mat-form-field>

        <sx-action-button
          [label]="'Select Organization' | translate"
          [type]="'submit'"
          class="public-card__action-button"
        ></sx-action-button>
        <sx-action-button
          [label]="'Back' | translate"
          [type]="'button'"
          class="public-card__action-button"
          [icon]="'chevron_left'"
          (action)="onBackButton()"
        ></sx-action-button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
