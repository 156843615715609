import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, EMPTY, of } from 'rxjs';
import { anyPass, endsWith } from 'ramda';
import { AuthApi } from '@generated/auth-api';
import { switchMapTo, tap } from 'rxjs/operators';
import { NotificationService } from '@shared/providers/notification.service';

/*
const MANAGED_DOWNLOADS = ['registrations'];
const isManagedDownload = anyPass(MANAGED_DOWNLOADS
  .map(name => `/${name}/download`)
  .map(endOfUrl => endsWith(endOfUrl)));
 */
const isManagedDownload = endsWith('/download');

/**
 * The download interceptor is used to capture download commands via our
 * generated swagger api and cancel the request while using another
 * method to download the file (as otherwise it will fail, as the
 * generated api cannot handle downloading files).
 *
 * We use a convention that the api endpoint needs to end in XXX/download, where
 * XXX stands for the the endpoint. For example we have an endpoint:
 *
 * /school/reservation/{reservationId}/registrations/download
 *
 * In order to activate this endpoint for the interceptor, you need to add
 * 'registrations' to the MANAGED_DOWNLOADS array in this file.
 */
@Injectable()
export class DownloadInterceptor implements HttpInterceptor {
  constructor(
    private authApi: AuthApi,
    private notifications: NotificationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isManagedDownload(req.url)) {
      // Calling me so the token is refreshed if necessary
      const trigger$: Observable<any> = req.headers.keys().includes('Authorization')
        ? this.authApi.me()
        : of(true);
      return trigger$.pipe(
        tap(() => this.download(req)),
        switchMapTo(EMPTY),
      );
    }
    return next.handle(req);
  }

  private download(req: HttpRequest<any>) {
    const request = new XMLHttpRequest();
    request.open(req.method, req.url, true);
    request.responseType = 'blob';
    req.headers.keys().forEach((key) => {
      request.setRequestHeader(key, req.headers.get(key));
    });
    request.onload = () => {
      if (request.status !== 200 && request.status !== 201) {
        this.notifications.error('There was a problem with downloading the export.');
        return;
      }

      const disposition = request.getResponseHeader('content-disposition');
      const matches = /.*filename=(.*)/.exec(disposition);
      const filename =
        matches != null && matches[1] ? decodeURIComponent(matches[1]) : 'export.xlsx';

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(request.response);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    request.onerror = () =>
      this.notifications.error('There was a problem with downloading the export.');
    request.send();
  }
}
