import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommitPasswordResetPayload } from '@shared/login/components/commit-password-reset/commit-password-reset-payload';
import { ValidationService } from '@shared/providers/validation.service';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../../../util/validation/validation-error-details';
import { passwordMatches } from '../../../../util/validators/passwords-match';

@Component({
  selector: 'sx-commit-password-reset-view',
  templateUrl: 'commit-password-reset.view.html',
})
export class CommitPasswordResetViewComponent implements AfterViewInit {
  /**
   * Indicates if this is the initial setting of a password
   */
  @Input()
  initial: boolean;

  form = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
    passwordConfirmation: new UntypedFormControl('', [
      Validators.required,
      passwordMatches(() => this.form && this.form.get('password')),
    ]),
  });

  getErrorDetails: ValidationErrorDetailsGetter;

  @Output()
  commitReset = new EventEmitter<CommitPasswordResetPayload>();

  @ViewChild('passwordField', { static: true })
  passwordField: ElementRef;

  constructor(
    private translate: TranslateService,
    private validation: ValidationService,
  ) {
    this.getErrorDetails = validationErrorDetails(this.form, translate);
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  onResetPassword() {
    this.validation.validateAndThen(this.form, (value) => this.commitReset.emit(value));
  }

  private setFocus(): void {
    setTimeout(() => {
      this.passwordField.nativeElement.focus();
    });
  }
}
