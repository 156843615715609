import { Component } from '@angular/core';
import { AccessTokenDto, AuthApi } from '@generated/auth-api';
import { AuthStateService } from '../../core/auth/auth-state.service';
import { Router } from '@angular/router';
import { LoginPayload } from '@shared/login/components/username-password-login/login-payload';
import { NotificationService } from '@shared/providers/notification.service';
import { afterOrgLogin } from '../../util/after-org-login';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sx-school-login-view',
  template: `
    <sx-username-password-login-view
      [label]="'Organization and School Access' | translate"
      [resetRouterLink]="['/', 'reset', 'initiate']"
      (login)="onLogin($event)"
    ></sx-username-password-login-view>
  `,
})
export class SchoolLoginViewComponent {
  constructor(
    private router: Router,
    private auth: AuthApi,
    private authState: AuthStateService,
    private notification: NotificationService,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Swiss Exams Access | Login for schools and organisations');
  }

  onLogin(payload: LoginPayload) {
    this.auth
      .loginWithPassword({
        email: payload.email,
        password: payload.password,
      })
      .subscribe(this.onLoggedIn.bind(this), (err) => {
        this.notification.error('Username or password incorrect');
      });
  }

  onLoggedIn(token: AccessTokenDto) {
    this.authState.onTokenAvailable(token);
    this.auth.me().subscribe({
      next: (me) => {
        this.authState.onLogin(me);
        afterOrgLogin(me, this.router);
      },
      error: (err) => {
        console.error('Could not retrieve user: ', err);
        this.notification.error('Login failed');
      },
    });
  }
}
