import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, Subject, throwError } from 'rxjs';
import { LoadingService } from 'src/app/core/loading/loading.service';
import { PaymentService } from '../../../app/payment/payment.service';
import { Destroyable } from '../../util/destroyable';

@Component({
  selector: 'sx-payment-view',
  templateUrl: './payment.view.html',
  styleUrls: ['./payment.view.scss'],
})
export class PaymentViewComponent extends Destroyable implements OnInit {
  private tenant: string;
  private invoiceCrmId: string;
  private contactCrmId: string;
  private errorSubject = new Subject();
  error$ = this.errorSubject.asObservable();

  constructor(
    private paymentService: PaymentService,
    private activatedRoute: ActivatedRoute,
    private loading: LoadingService,
  ) {
    super();
  }

  onStartPaymentClick() {
    // Get the payment redirect
    this.loading.start(PaymentViewComponent.name);

    this.paymentService
      .init(this.tenant, this.invoiceCrmId, this.contactCrmId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorSubject.next(error.error);
          return throwError(() => error);
        }),
        this.takeUntilDestroyed(),
        finalize(() => this.loading.stop(PaymentViewComponent.name)),
      )
      .subscribe();
  }

  ngOnInit() {
    const paramsMap = this.activatedRoute.snapshot.paramMap;
    this.invoiceCrmId = paramsMap.get('invoiceCrmId');
    this.contactCrmId = paramsMap.get('contactCrmId');

    // TODO: SEMP-545 use a place holder tenant for the moment
    this.tenant = 'SX';
  }
}
