import { Component, HostBinding } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { TranslateLanguageService } from '../../translation/translate-language.service';
import { FORMAL_MAPPING, LANGUAGES } from '../../translation/languages';

@Component({
  selector: 'sx-public-frame-view',
  templateUrl: 'public-frame.view.html',
})
export class PublicFrameViewComponent {
  @HostBinding('class')
  private cssClass = 'public-frame';

  languages = LANGUAGES;
  formalMapping = FORMAL_MAPPING;

  constructor(
    public translate: TranslateService,
    public translateLanguage: TranslateLanguageService,
    private localStorage: LocalStorageService,
  ) {}

  onChangeLang(lang: string) {
    this.translateLanguage.lang = lang;
    this.localStorage.put('lang', lang);
  }
}
