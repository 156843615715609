import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatValues',
  pure: true,
})
export class ConcatValuesPipe implements PipeTransform {
  transform(value: any, separator = ' '): any {
    if (!value) {
      return '';
    }
    if (!Array.isArray(value)) {
      return value;
    }
    return value.filter((item) => !!item).join(separator);
  }
}
