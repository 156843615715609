/**
 * CanidateApi
 * Swiss Exams - Microservices - Candidate Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CandidateAttachmentDto { 
    id: string;
    filename: string;
    container: string;
    blobName: string;
    size: number;
    extension: string;
    uploadedOn?: string;
    type: CandidateAttachmentDto.TypeEnum;
    requestSentOn?: string;
    contactCrmId: string;
    registrationCrmId: string;
}
export namespace CandidateAttachmentDto {
    export type TypeEnum = 'EroRequest';
    export const TypeEnum = {
        EroRequest: 'EroRequest' as TypeEnum
    };
}


