import { trigger, transition, style, query, group, animate } from '@angular/animations';

export const fader = trigger('routeAnimations', [
  transition('* <=> *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          opacity: 0,
        }),
      ],
      { optional: true },
    ),
    query(
      ':enter',
      [
        animate(
          '600ms ease',
          style({
            width: '100%',
            opacity: 1,
          }),
        ),
      ],
      { optional: true },
    ),
  ]),
]);

export let slider = trigger('routeAnimations', [
  transition('isTop => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: '32px',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [
      style({
        top: '100%',
      }),
    ]),
    group([
      query(':leave', [animate('600ms ease', style({ top: '-100%' }))], { optional: true }),
      query(':enter', [animate('600ms ease', style({ top: '32px' }))]),
    ]),
  ]),
  transition('isBottom => *', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: '32px',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [
      style({
        top: '-100%',
      }),
    ]),
    group([
      query(':leave', [animate('600ms ease', style({ top: '100%' }))], { optional: true }),
      query(':enter', [animate('600ms ease', style({ top: '32px' }))]),
    ]),
  ]),
]);
