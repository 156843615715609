import { Inject, Injectable } from '@angular/core';
import { Configuration as AuthConfig } from '@generated/auth-api/configuration';
import { Configuration as CandidateConfig } from '@generated/candidate-api/configuration';
import { Configuration as SchoolConfig } from '@generated/school-api/configuration';
import { Configuration as SaConfig } from '@generated/sa-api/configuration';
import { Configuration as StaffConfig } from '@generated/staff-api/configuration';
import { Configuration as PaymentConfig } from '@generated/payment-api/configuration';

import { AuthApi } from '@generated/auth-api';
import {
  ContactApi,
  RequestApi,
  ExamsApi as CandidateExamsService,
} from '@generated/candidate-api';
import {
  BulkRegistrationApi,
  ExamsApi as SchoolExamsService,
  SchoolApi,
} from '@generated/school-api';
import { SkillsAssessmentApi } from '@generated/sa-api';
import { LinguaskillService } from '@shared/providers/linguaskill.service';
import { AvailabilityApi } from '@generated/staff-api';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _apiKey: string;

  constructor(
    @Inject(AuthConfig)
    private authConfig: AuthConfig,
    private authApi: AuthApi,
    @Inject(CandidateConfig)
    private candidateConfig: CandidateConfig,
    private contactApi: ContactApi,
    private candidateExamsService: CandidateExamsService,
    private requestApi: RequestApi,
    @Inject(PaymentConfig)
    private paymentConfig: PaymentConfig,
    @Inject(SchoolConfig)
    private schoolConfig: SchoolConfig,
    @Inject(SaConfig)
    private saConfig: SaConfig,
    @Inject(StaffConfig)
    private staffConfig: StaffConfig,
    private schoolApi: SchoolApi,
    private saApi: SkillsAssessmentApi,
    private schoolExamsService: SchoolExamsService,
    private bulkRegistrationApi: BulkRegistrationApi,
    private linguasklillService: LinguaskillService,
    private availabilityApi: AvailabilityApi,
  ) {
    this.apiKey = '';
  }

  set authBasePath(basePath: string) {
    this.authConfig.basePath = basePath;
    (this.authApi as any).basePath = basePath;
  }

  get authBasePath() {
    return this.authConfig.basePath;
  }

  set candidateBasePath(basePath: string) {
    this.candidateConfig.basePath = basePath;
    (this.contactApi as any).basePath = basePath;
    (this.candidateExamsService as any).basePath = basePath;
    (this.requestApi as any).basePath = basePath;
  }

  get candidateBasePath() {
    return this.candidateConfig.basePath;
  }

  set paymentBasePath(basePath: string) {
    this.paymentConfig.basePath = basePath;
  }

  get paymentBasePath() {
    return this.paymentConfig.basePath;
  }

  set schoolBasePath(basePath: string) {
    this.schoolConfig.basePath = basePath;
    (this.schoolApi as any).basePath = basePath;
    (this.schoolExamsService as any).basePath = basePath;
    (this.bulkRegistrationApi as any).basePath = basePath;
  }

  get schoolBasePath() {
    return this.schoolConfig.basePath;
  }

  set saBasePath(basePath: string) {
    this.saConfig.basePath = basePath;
    (this.saApi as any).basePath = basePath;
    (this.linguasklillService as any).basePath = basePath;
  }

  get saBasePath() {
    return this.saConfig.basePath;
  }

  set staffBasePath(basePath: string) {
    this.staffConfig.basePath = basePath;
    (this.availabilityApi as any).basePath = basePath;
  }

  get staffBasePath() {
    return this.staffConfig.basePath;
  }

  set apiKey(apiKey: string) {
    this._apiKey = apiKey;
    const auth = {
      Authorization: `Bearer ${apiKey}`,
    };
    this.authConfig.apiKeys = auth;
    this.authConfig.accessToken = apiKey;
    this.candidateConfig.apiKeys = auth;
    this.candidateConfig.accessToken = apiKey;
    this.paymentConfig.apiKeys = auth;
    this.paymentConfig.accessToken = apiKey;
    this.schoolConfig.apiKeys = auth;
    this.schoolConfig.accessToken = apiKey;
    this.saConfig.apiKeys = auth;
    this.saConfig.accessToken = apiKey;
    this.staffConfig.apiKeys = auth;
    this.staffConfig.accessToken = apiKey;
  }

  get apiKey() {
    return this._apiKey;
  }
}
