import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface ValidationErrorDetails {
  message: string;
  params?: any;
}

export type ValidationErrorDetailsGetter = (
  fieldName: string | string[],
  fieldLabel: string,
) => ValidationErrorDetails | void;

export function validationErrorDetails(
  form: UntypedFormGroup,
  translate: TranslateService,
): ValidationErrorDetailsGetter {
  return function validate(
    fieldNames: string | string[],
    fieldLabel: string,
  ): ValidationErrorDetails | void {
    if (Array.isArray(fieldNames)) {
      for (const fieldName of fieldNames) {
        const res = validate(fieldName, fieldLabel);
        if (res) {
          return res;
        }
      }
      return undefined;
    }

    const field = fieldNames === '' ? form : form.get(fieldNames);
    if (!field || field.untouched) {
      return;
    }
    const { errors } = field;
    if (!errors) {
      return;
    }
    const params = {
      label: fieldLabel ? translate.instant(fieldLabel) : translate.instant('field'),
    };
    if (errors.required) {
      return {
        message: `'$label' is required.`,
        params,
      };
    } else if (errors.minlength) {
      return {
        message: `'$label' needs to be at least $requiredLength characters long. Actual length is $actualLength`,
        params: {
          ...params,
          ...errors.minlength,
        },
      };
    } else if (errors.min) {
      return {
        message: `'$label' needs to be at least $min. Actual length is $actual`,
        params: {
          ...params,
          ...errors.min,
        },
      };
    } else if (errors.passwordDoesNotMatch) {
      return {
        message: `Both passwords need to be the same.`,
        params,
      };
    } else if (errors.requiredWhen && typeof errors.requiredWhen === 'string') {
      return {
        message: errors.requiredWhen,
        params,
      };
    } else if (errors.email) {
      return {
        message: `'$label' is not a valid email address.`,
        params,
      };
    }
    return {
      message: 'Value is invalid',
      params: {
        ...params,
        errors,
      },
    };
  };
}
