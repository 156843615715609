import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponsiveModule } from './responsive/responsive.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { CommonModule, DatePipe } from '@angular/common';
import { KeyValueComponent } from '@shared/key-value/key-value.component';
import { ContactDetailsAddressComponent } from '@shared/contact-details/contact-details-address.component';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog.component';
import { CommentDialogComponent } from '@shared/dialogs/comment-dialog.component';
import { InPlaceEditorComponent } from '@shared/in-place-editor/in-place-editor.component';
import { FormViewGuard } from '@shared/form-view/form-view.guard';
import { NativeFormViewTrackerComponent } from '@shared/form-view/native-form-view-tracker.component';
import { FontawesomeModule } from './fontawesome.module';
import { InformationDialogComponent } from './dialogs/information-dialog.component';
import { PublicFrameViewComponent } from '@shared/public-frame/public-frame.view';
import { RouterModule } from '@angular/router';
import { ContactResolver } from '@shared/providers/contact.resolver';
import { ExamsResolver } from '@shared/providers/exams.resolver';
import { CardLayoutViewComponent } from '@shared/card-layout/card-layout.view';
import { PasswordlessLoginViewComponent } from '@shared/login/components/passwordless-login/passwordless-login.view';
import { UsernamePasswordLoginViewComponent } from '@shared/login/components/username-password-login/username-password-login.view';
import { ResetPasswordRequestViewComponent } from '@shared/login/components/reset-password-request/reset-password-request.view';
import { CommitPasswordResetViewComponent } from '@shared/login/components/commit-password-reset/commit-password-reset.view';
import { CanActivateCommitPasswordResetGuard } from '@shared/login/components/commit-password-reset/can-activate-commit-password-reset.guard';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatedMatPaginatorIntl } from '@shared/providers/translated-mat-paginator-intl';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { ValidationService } from '@shared/providers/validation.service';
import { NotificationService } from '@shared/providers/notification.service';
import { ChangePasswordViewComponent } from '@shared/change-password/change-password.view';
import { ErrorViewComponent } from '@shared/error/error.view';
import { LoadingDialogComponent } from '@shared/dialogs/loading-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { StoreModule } from '@ngrx/store';
import { pipes } from '@shared/pipes';
import { TenantService } from '@shared/tenant/tenant.service';
import { ValidationMessagesComponent } from '@shared/validation-message/validation-message.component';
import { LoadingSpinnerComponent } from '@shared/loading-spinner/loading-spinner.component';
import { buttonComponents } from '@shared/action-button';
import { ExternalLinkDialogComponent } from '@shared/dialogs/external-link-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CopyToClipboardComponent } from '@shared/copy-to-clipboard/copy-to-clipboard.component';
import { OrgGuard } from '@shared/providers/org.guard';
import { OrgSelectionComponent } from '@shared/login/components/org-selection/org-selection.component';
import { OrgUserSignupComponent } from './login/components/org-user-signup/org-user-signup.component';
import { AnnouncementBannerComponent } from './announcement-banner/announcement-banner.component';
import { RegistrationResolver } from './providers/registration.resolver';
import { TooltipModule } from 'ng2-tooltip-directive';
import { DialogBaseLayoutComponent } from './dialogs/dialog-base-layout/dialog-base-layout.component';

const IMPORT_AND_EXPORT = [
  CommonModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MaterialModule,
  FontawesomeModule,
  ResponsiveModule,
  NgxFileDropModule,
  StoreModule,
  ClipboardModule,
  TooltipModule,
];

const DECLARE_ENTRY_AND_EXPORT = [
  LoadingDialogComponent,
  ConfirmationDialogComponent,
  CommentDialogComponent,
  InformationDialogComponent,
  ErrorViewComponent,
  ExternalLinkDialogComponent,
  AnnouncementBannerComponent,
];

const DECLARE_AND_EXPORT = [
  ...DECLARE_ENTRY_AND_EXPORT,
  ...buttonComponents,
  PublicFrameViewComponent,
  CardLayoutViewComponent,
  KeyValueComponent,
  ContactDetailsComponent,
  ContactDetailsAddressComponent,
  InPlaceEditorComponent,
  NativeFormViewTrackerComponent,
  ValidationMessagesComponent,
  LoadingSpinnerComponent,
  CopyToClipboardComponent,
  // Login and Account
  PasswordlessLoginViewComponent,
  UsernamePasswordLoginViewComponent,
  OrgUserSignupComponent,
  ResetPasswordRequestViewComponent,
  CommitPasswordResetViewComponent,
  ChangePasswordViewComponent,
  OrgSelectionComponent,
  DialogBaseLayoutComponent,

  // Pipes
  ...pipes,
];

@NgModule({
  imports: [...IMPORT_AND_EXPORT],
  providers: [
    LocalStorageService,
    FormViewGuard,
    ContactResolver,
    ExamsResolver,
    CanActivateCommitPasswordResetGuard,
    DatePipe,
    { provide: MatPaginatorIntl, useClass: TranslatedMatPaginatorIntl },
    GenericDialogs,
    ValidationService,
    NotificationService,
    TenantService,
    OrgGuard,
    RegistrationResolver,
  ],
  declarations: [...DECLARE_AND_EXPORT],
  exports: [...IMPORT_AND_EXPORT, ...DECLARE_AND_EXPORT],
})
export class SharedModule {}
