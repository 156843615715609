import { Component } from '@angular/core';
import { Destroyable } from '../../util/destroyable';
import { ResetPasswordRequestPayload } from '@shared/login/components/reset-password-request/reset-password-request-payload';
import { AuthApi } from '@generated/auth-api';
import { Router } from '@angular/router';
import { NotificationService } from '@shared/providers/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sx-school-reset-password-view',
  template: `
    <sx-reset-password-request-view
      (requestReset)="onResetPasswordRequest($event)"
    ></sx-reset-password-request-view>
  `,
})
export class SchoolResetPasswordViewComponent extends Destroyable {
  constructor(
    private auth: AuthApi,
    private notification: NotificationService,
    private router: Router,
    public translate: TranslateService,
  ) {
    super();
  }

  onResetPasswordRequest(payload: ResetPasswordRequestPayload) {
    this.auth
      .requestPasswordReset({
        email: payload.email,
        language: this.translate.currentLang,
        type: 'resetPassword',
      })
      .subscribe(
        () => {
          this.notification.success('Password Reset Request Success Message');
          this.router.navigate(['/', 'login', 'school']);
        },
        () => this.notification.error('Password Reset Request Failed Message'),
      );
  }
}
