<sx-card-layout-view label="Personal Data" icon="fingerprint" subTitle="Personal Data Description">
  <div class="card-layout-buttons">
    <ng-template [ngIf]="!editing">
      <button
        mat-mini-fab
        color="secondary"
        aria-label="'Edit Address' | translate"
        (click)="onEdit()"
      >
        <mat-icon color="primary">create</mat-icon>
      </button>
    </ng-template>
    <ng-template [ngIf]="editing">
      <button
        mat-mini-fab
        color="secondary"
        aria-label="'Cancel Editing' | translate"
        (click)="onCancelEdit()"
      >
        <mat-icon color="primary">cancel</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="secondary"
        aria-label="'Save Address' | translate"
        (click)="onSave()"
      >
        <mat-icon color="primary">save</mat-icon>
      </button>
    </ng-template>
  </div>

  <form [formGroup]="form">
    <sx-key-value [key]="'Salutation' | translate">
      <sx-in-place-editor>
        {{ _contact?.gender ? ('Salutations.' + _contact?.gender | translate) : '' }}
        <div class="editor">
          <mat-form-field>
            <mat-select formControlName="salutation">
              <mat-option *ngFor="let s of salutations" [value]="s">
                {{ 'Salutations.' + s | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getErrorDetails('salutation', 'Salutation') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'First Name' | translate">
      <sx-in-place-editor>
        {{ firstName }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="firstName" />
            <mat-error *ngIf="getErrorDetails('firstName', 'First Name') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Last Name' | translate">
      <sx-in-place-editor>
        {{ lastName }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="lastName" />
            <mat-error *ngIf="getErrorDetails('lastName', 'Last Name') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-contact-details-address
      [address]="mainAddress"
      [addressForm]="form.get('address')"
    ></sx-contact-details-address>
    <sx-key-value [key]="'Private Phone' | translate">
      <sx-in-place-editor>
        {{ privatePhone }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="privatePhone" />
            <mat-error *ngIf="getErrorDetails('privatePhone', 'Private Phone') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Work Phone' | translate">
      <sx-in-place-editor>
        {{ workPhone }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="workPhone" />
            <mat-error *ngIf="getErrorDetails('workPhone', 'Work Phone') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Email Address' | translate">
      {{ _contact?.email }}
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Date of Birth' | translate">
      <sx-in-place-editor>
        {{ dateOfBirth | sxDate }}
        <div class="editor">
          <mat-form-field>
            <input matInput formControlName="dateOfBirth" [matDatepicker]="dateOfBirthPicker" />
            <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirthPicker></mat-datepicker>
            <mat-error *ngIf="getErrorDetails('dateOfBirth', 'Date of Birth') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <ng-template [ngIf]="showPlaceOfBirth">
      <sx-key-value [key]="'Place of Birth' | translate">
        <sx-in-place-editor>
          {{ placeOfBirth }}
          <div class="editor">
            <mat-form-field>
              <input matInput type="text" formControlName="placeOfBirth" />
              <mat-error *ngIf="getErrorDetails('placeOfBirth', 'Place of Birth') as details">
                {{ details.message | translate: details.params }}
              </mat-error>
            </mat-form-field>
          </div>
        </sx-in-place-editor>
      </sx-key-value>
      <mat-divider></mat-divider>
    </ng-template>
    <sx-key-value [key]="'Country Of Birth' | translate">
      <sx-in-place-editor>
        {{ countryOfBirth }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="countryOfBirth" />
            <mat-error *ngIf="getErrorDetails('countryOfBirth', 'Country Of Birth') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Nationality' | translate">
      <sx-in-place-editor>
        {{ nationality }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="nationality" />
            <mat-error *ngIf="getErrorDetails('nationality', 'Nationality') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
    <sx-key-value [key]="'Native Language' | translate">
      <sx-in-place-editor>
        {{ nativeLanguage }}
        <div class="editor">
          <mat-form-field>
            <input matInput type="text" formControlName="nativeLanguage" />
            <mat-error *ngIf="getErrorDetails('nativeLanguage', 'Native Language') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
      </sx-in-place-editor>
    </sx-key-value>
    <mat-divider></mat-divider>
  </form>
</sx-card-layout-view>
