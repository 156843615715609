<mat-card appearance="raised" class="public-card">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary">vpn_key</mat-icon>
      <span>{{ label }}</span>
    </mat-card-title>
    <mat-card-subtitle
      *ngIf="context === 'school'"
      [innerHTML]="'Username Password Login Info Description' | translate"
    ></mat-card-subtitle>
    <mat-card-subtitle
      *ngIf="context === 'staff'"
      [innerHTML]="'Username Password Staff Login Info Description' | translate"
    ></mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="public-card__content">
    <form [formGroup]="form" (ngSubmit)="onLogin()" class="public-card__form">
      <mat-form-field>
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input #emailField matInput type="email" formControlName="email" />
        <mat-error *ngIf="validate('email', 'Email') as details">
          {{ details.message | translate : details.params }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input matInput type="password" formControlName="password" />
        <mat-error *ngIf="validate('password', 'Password') as details">
          {{ details.message | translate : details.params }}
        </mat-error>
      </mat-form-field>
      <sx-action-button
        [label]="'Log in' | translate"
        [type]="'submit'"
        class="public-card__action-button"
      ></sx-action-button>
    </form>
    <mat-card-actions class="public-card__actions">
      <sx-action-button
        *ngIf="resetRouterLink?.length"
        [label]="'I forgot my password' | translate"
        [type]="'button'"
        [small]="true"
        (action)="onResetPassword()"
      ></sx-action-button>
      <sx-action-button
        [label]="'I want to create an account' | translate"
        [type]="'button'"
        [small]="true"
        class="public-card__action-button"
        (action)="onCreateAccount()"
      ></sx-action-button>
      <sx-router-link-button
        [label]="'Candidate Access' | translate"
        [type]="'button'"
        [small]="true"
        [link]="'../candidate'"
        class="public-card__action-button"
      ></sx-router-link-button>

      <sx-router-link-button
        *ngIf="context === 'staff'"
        [label]="'Organization and School Access' | translate"
        [type]="'button'"
        [small]="true"
        [link]="'../school'"
        class="public-card__action-button"
      ></sx-router-link-button>
      <sx-router-link-button
        *ngIf="context === 'school'"
        [label]="'Staff Access Title' | translate"
        [type]="'button'"
        [small]="true"
        [link]="'../staff'"
        class="public-card__action-button"
      ></sx-router-link-button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
