/**
 * CanidateApi
 * Swiss Exams - Microservices - Candidate Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CandidateAttachmentDto } from './candidateAttachmentDto';


export interface ExamRequestDto { 
    examId: string;
    speakingExamId?: string;
    examModuleId?: string;
    registrationId: string;
    requestType: ExamRequestDto.RequestTypeEnum;
    newExamId?: string;
    comment?: string;
    language: string;
    attachment?: CandidateAttachmentDto;
    isNarrowFail?: boolean;
    speakingFirstName?: string;
    speakingLastName?: string;
}
export namespace ExamRequestDto {
    export type RequestTypeEnum = 'RescheduleExam' | 'RequestInvoice' | 'RequestCertificate' | 'RequestExamRetakeOptionCode' | 'RequestSpeakingPartner' | 'RequestResultEnquiry' | 'NotifyOfAbsence';
    export const RequestTypeEnum = {
        RescheduleExam: 'RescheduleExam' as RequestTypeEnum,
        RequestInvoice: 'RequestInvoice' as RequestTypeEnum,
        RequestCertificate: 'RequestCertificate' as RequestTypeEnum,
        RequestExamRetakeOptionCode: 'RequestExamRetakeOptionCode' as RequestTypeEnum,
        RequestSpeakingPartner: 'RequestSpeakingPartner' as RequestTypeEnum,
        RequestResultEnquiry: 'RequestResultEnquiry' as RequestTypeEnum,
        NotifyOfAbsence: 'NotifyOfAbsence' as RequestTypeEnum
    };
}


