import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccessTokenDto, AuthApi, UserDto } from '@generated/auth-api';
import { LoginPayload } from '@shared/login/components/username-password-login/login-payload';
import { NotificationService } from '@shared/providers/notification.service';
import { EMPTY } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthStateService } from 'src/app/core/auth/auth-state.service';

@Component({
  selector: 'sx-staff-login',
  templateUrl: './staff-login.component.html',
  styleUrls: ['./staff-login.component.scss'],
})
export class StaffLoginComponent {
  constructor(
    private router: Router,
    private auth: AuthApi,
    private authState: AuthStateService,
    private notification: NotificationService,
  ) {}

  context: UserDto.ContextEnum = UserDto.ContextEnum.Staff;

  onLogin(payload: LoginPayload) {
    this.auth
      .loginAsStaff({
        email: payload.email,
        password: payload.password,
      })
      .pipe(
        catchError(() => {
          this.notification.error('Username or password incorrect');
          return EMPTY;
        }),
        switchMap((token) => this.onLoggedIn(token)),
      )
      .subscribe();
  }

  private onLoggedIn(token: AccessTokenDto) {
    this.authState.onTokenAvailable(token);

    return this.auth.me().pipe(
      tap(() => this.authState.onTokenAvailable(token)),
      catchError((error) => {
        console.error('Could not retrieve user: ', error);
        this.notification.error('Login failed');
        return EMPTY;
      }),
      tap((user) => this.authState.onLogin(user)),
      tap(() => this.router.navigateByUrl('/staff')),
    );
  }
}
