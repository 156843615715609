import { Component, Input, Optional } from '@angular/core';
import { Destroyable } from '../../util/destroyable';
import { InPlaceEditorService } from '@shared/in-place-editor/in-place-editor.service';

@Component({
  selector: 'sx-in-place-editor',
  template: `
    <ng-template [ngIf]="editing">
      <ng-content select=".editor"></ng-content>
    </ng-template>
    <ng-template [ngIf]="!editing">
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class InPlaceEditorComponent extends Destroyable {
  editing: boolean;

  @Input('editing')
  set _editing(editing: boolean) {
    this.editing = editing;
  }

  constructor(
    @Optional()
    service: InPlaceEditorService,
  ) {
    super();
    if (service) {
      service.editing$
        .pipe(this.takeUntilDestroyed())
        .subscribe((editing) => (this._editing = editing));
    }
  }
}
