import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddressDto, ContactDto, ContactRequestDto } from '@generated/candidate-api';
import { TranslateService } from '@ngx-translate/core';
import { InPlaceEditorService } from '@shared/in-place-editor/in-place-editor.service';
import { NotificationService } from '@shared/providers/notification.service';
import { ValidationService } from '@shared/providers/validation.service';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { Destroyable } from '../../util/destroyable';
import { traverseControl } from '../../util/traverse-control';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../util/validation/validation-error-details';
import { requiredWhen } from '../../util/validators/required-when';

export const LOADING_KEY__REQUEST_CONTACT_CHANGES = 'ContactDetails.RequestContactChanges';

@Component({
  selector: 'sx-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  providers: [InPlaceEditorService],
  encapsulation: ViewEncapsulation.None,
})
export class ContactDetailsComponent extends Destroyable {
  _contact: ContactDto;
  mainAddress: AddressDto;
  billingAddress: AddressDto;

  firstName: string;
  lastName: string;
  privatePhone: string;
  workPhone: string;

  dateOfBirth: string;
  placeOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  nativeLanguage: string;

  editing = false;

  private _hasChanged = false;

  get hasChanged() {
    return this._hasChanged;
  }

  set hasChanged(hasChanged: boolean) {
    this._hasChanged = hasChanged;
    this.hasUnsavedChanges.emit(hasChanged);
  }

  @HostBinding('class')
  private cssClass = 'contact-details';

  @Input('editing')
  set _editing(editing: boolean) {
    const startEditing = !this.editing && editing;
    this.editing = editing;
    this.editingService.editing = editing;
    if (startEditing) {
      const c = this._contact;
      const a = this._contact.contactAddress || ({} as any);

      // Fix time offset of dates to show correct date regardless of current timezone
      let dateOfBirth;
      if (c.dateOfBirth) {
        const momentDate = moment(c.dateOfBirth);
        const offset = momentDate.utcOffset();
        dateOfBirth = moment(c.dateOfBirth).utcOffset(offset * -1 + offset + 120);
      }

      this.form.patchValue({
        salutation: c.gender,
        firstName: c.firstName,
        lastName: c.lastName,
        privatePhone: c.privatePhone,
        workPhone: c.workPhone,
        dateOfBirth: dateOfBirth,
        placeOfBirth: c.placeOfBirth,
        countryOfBirth: c.countryOfBirth,
        nationality: c.nationality,
        nativeLanguage: c.nativeLanguage,
        address: {
          line1: a.line1,
          line2: a.line2,
          postalCode: a.postalCode,
          city: a.city,
          country: a.country,
        },
      });
      traverseControl(this.form, (control) => control.markAsUntouched());
      this.hasChanged = false;
    }
    this.cssClass = this.editing ? 'contact-details contact-details--editing' : 'contact-details';
  }

  @Input()
  set contact(contact: ContactDto) {
    this.showPlaceOfBirth = !!contact.placeOfBirth;
    this.form.get('placeOfBirth').clearValidators();
    if (this.showPlaceOfBirth) {
      this.form.get('placeOfBirth').setValidators(Validators.required);
    }
    this._contact = contact;
    this.firstName = '';
    this.lastName = '';
    this.privatePhone = '';
    this.workPhone = '';
    if (!contact) {
      return;
    }
    this.mainAddress = contact.contactAddress;
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.privatePhone = contact.privatePhone || '';
    this.workPhone = contact.workPhone || '';
    this.dateOfBirth = contact.dateOfBirth || '';
    this.placeOfBirth = contact.placeOfBirth || '';
    this.countryOfBirth = contact.countryOfBirth || '';
    this.nationality = contact.nationality || '';
    this.nativeLanguage = contact.nativeLanguage || '';
  }

  @Output()
  edit = new EventEmitter<any>();

  @Output()
  cancelEdit = new EventEmitter<boolean>();

  @Output()
  save = new EventEmitter<ContactRequestDto>();

  @Output()
  hasUnsavedChanges = new EventEmitter<boolean>();

  showPlaceOfBirth = false;

  form: UntypedFormGroup;

  salutations = ['Male', 'Female'];
  getErrorDetails: ValidationErrorDetailsGetter;

  constructor(
    private editingService: InPlaceEditorService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private translate: TranslateService,
    private validation: ValidationService,
  ) {
    super();
    this.hasChanged = false;
    this.form = fb.group({
      salutation: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      privatePhone: [
        '',
        requiredWhen(
          () => this.form && !this.form.get('workPhone').value,
          `Either private or work phone needs to be filled out.`,
        ),
      ],
      workPhone: [
        '',
        requiredWhen(
          () => this.form && !this.form.get('privatePhone').value,
          `Either private or work phone needs to be filled out.`,
        ),
      ],
      dateOfBirth: ['', Validators.required],
      placeOfBirth: [''],
      countryOfBirth: [''],
      nationality: [''],
      nativeLanguage: [''],
      address: fb.group({
        line1: ['', Validators.required],
        line2: [''],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', Validators.required],
      }),
    });
    this.getErrorDetails = validationErrorDetails(this.form, translate);

    const workPhone = this.form.get('workPhone');
    const privatePhone = this.form.get('privatePhone');
    this.form.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => (this.hasChanged = true));

    workPhone.valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe(() => privatePhone.updateValueAndValidity());
    privatePhone.valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe(() => workPhone.updateValueAndValidity());
  }

  onEdit() {
    this.edit.emit();
  }

  onCancelEdit() {
    this.cancelEdit.emit(this.hasChanged);
  }

  onSave() {
    if (!this._contact) {
      return;
    }
    if (!this.hasChanged) {
      this.notification.info('There were no changes');
      this.onCancelEdit();
      return;
    }
    this.validation.validateAndThen(this.form, () => {
      const { value } = this.form;
      const a = value.address;
      this.save.emit({
        language: '',
        contactId: this._contact.crmId,
        requestType: ContactRequestDto.RequestTypeEnum.ContactChange,
        updatedContact: {
          ...this._contact,
          gender: value.salutation,
          firstName: value.firstName,
          lastName: value.lastName,
          dateOfBirth: value.dateOfBirth
            ? moment(value.dateOfBirth).format('YYYY-MM-DD')
            : undefined,
          placeOfBirth: value.placeOfBirth,
          countryOfBirth: value.countryOfBirth,
          nationality: value.nationality,
          nativeLanguage: value.nativeLanguage,
          privatePhone: value.privatePhone,
          workPhone: value.workPhone,
          contactAddress: {
            ...(this._contact.contactAddress || {
              companyName: '',
              recipient: '',
            }),
            line1: a.line1,
            line2: a.line2,
            postalCode: a.postalCode,
            city: a.city,
            country: a.country,
          },
        },
      });
      this.hasChanged = false;
    });
  }
}
