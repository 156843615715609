import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PublicRoutingModule } from './public-routing.module';
import { CandidateLoginViewComponent } from './candidate-login/candidate-login.view';
import { SchoolLoginViewComponent } from './school-login/school-login.view';
import { SchoolResetPasswordViewComponent } from './school-login/school-reset-password.view';
import { SchoolCommitPasswordResetViewComponent } from './school-login/school-commit-password-reset.view';
import { HomeViewComponent } from './home/home.view';
import { AttachmentViewComponent } from './attachment/attachment.view';
import { StaffLoginComponent } from './staff-login/staff-login.component';
import { StaffInitialPasswortComponent } from './staff-initial-passwort/staff-initial-passwort.component';
import { PaymentViewComponent } from './payment/payment.view';
import { PaymentService } from '../payment/payment.service';
import { SuccessPaymentViewComponent } from './payment/success-payment.view';
import { FailPaymentViewComponent } from './payment/fail-payment.view';
import { PaymentFrameViewComponent } from './payment/payment-frame.view';
import { TenantResolver } from './payment/tenant.resolver';
import { DefaultPaymentViewComponent } from './payment/default.view';

const DECLARE_AND_EXPORT = [
  HomeViewComponent,
  DefaultPaymentViewComponent,
  PaymentViewComponent,
  PaymentFrameViewComponent,
  SuccessPaymentViewComponent,
  FailPaymentViewComponent,
  CandidateLoginViewComponent,
  SchoolLoginViewComponent,
  SchoolResetPasswordViewComponent,
  SchoolCommitPasswordResetViewComponent,
  AttachmentViewComponent,
  StaffLoginComponent,
  StaffInitialPasswortComponent,
];

@NgModule({
  imports: [SharedModule, PublicRoutingModule],
  providers: [PaymentService, TenantResolver],
  declarations: [...DECLARE_AND_EXPORT],
  exports: [...DECLARE_AND_EXPORT],
})
export class PublicModule {}
