import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetchCandidateHasExams, setCandidateHasExams } from './candidate-exams.actions';
import { catchError, distinctUntilChanged, map, pluck, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AuthStateService } from '../../auth/auth-state.service';
import { UserDto } from '@generated/auth-api';
import { ExamsApi } from '@generated/candidate-api';
import has = Reflect.has;

@Injectable()
export class CandidateExamsEffects {
  fetchActions$ = createEffect(() =>
    this.actions$.pipe(ofType(fetchCandidateHasExams), switchMap(this.fetchExams.bind(this))),
  );

  constructor(
    private actions$: Actions,
    private api: ExamsApi,
    private authStateService: AuthStateService,
    private store: Store,
  ) {
    authStateService.state$
      .pipe(pluck('user'), distinctUntilChanged())
      .subscribe(this.onUserChanged.bind(this));
  }

  onUserChanged(user: UserDto | undefined) {
    if (user && user.isCandidate) {
      this.store.dispatch(fetchCandidateHasExams());
    } else {
      this.store.dispatch(setCandidateHasExams({ hasExams: false }));
    }
  }

  fetchExams(): Observable<Action> {
    return this.api.userExamsExists().pipe(
      map((hasExams) => setCandidateHasExams({ hasExams: hasExams })),
      catchError((error) => {
        // TODO: Error Handling
        console.error(error);
        return of(setCandidateHasExams({ hasExams: false }));
      }),
    );
  }
}
