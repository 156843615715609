<mat-card appearance="raised" class="public-card">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary">vpn_key</mat-icon>
      <span>{{ 'Reset Password' | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle
      [innerHTML]="'Init Password Reset Description' | translate"
    ></mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="public-card__content">
    <form [formGroup]="form" (ngSubmit)="onRequestReset()" class="public-card__form">
      <mat-form-field>
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input #emailField matInput type="email" formControlName="email" />
        <mat-hint>{{ 'Enter your email address.' | translate }}</mat-hint>
        <mat-error *ngIf="getErrorDetails('email', 'Email') as details">
          {{ details.message | translate : details.params }}
        </mat-error>
      </mat-form-field>

      <sx-action-button
        [label]="'Request Password Reset Link' | translate"
        [type]="'submit'"
        class="public-card__action-button"
      ></sx-action-button>
    </form>
  </mat-card-content>
</mat-card>
