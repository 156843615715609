import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

@Injectable()
export class TranslatedMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private translate: TranslateService) {
    super();
    translate.onLangChange
      .pipe(startWith(translate.currentLang), takeUntil(this.destroy$))
      .subscribe(this.onUpdateTranslations.bind(this));
  }

  onUpdateTranslations() {
    this.firstPageLabel = this.translate.instant('First Page');
    this.lastPageLabel = this.translate.instant('Last Page');
    this.previousPageLabel = this.translate.instant('Previous Page');
    this.nextPageLabel = this.translate.instant('Next Page');
    this.itemsPerPageLabel = this.translate.instant('Items per Page');
    this.changes.next(null);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('$start - $end of $total', {
      start: startIndex + 1,
      end: endIndex,
      total: length,
    });
  };
}
