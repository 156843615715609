import { UserDto } from '@generated/auth-api';
import { Router } from '@angular/router';

export function afterOrgLogin(user: UserDto | undefined, router: Router) {
  void router.navigate(getRouteForAfterLogin(user));
}

export function getRouteForAfterLogin(user?: UserDto): string[] {
  if (!user) {
    return ['/login/school'];
  } else if (!user.activeOrg) {
    return ['/school/select-org'];
  } else if (user.rights.includes(UserDto.RightsEnum.AccessTeacherManageReservations)) {
    return ['/school/reservations'];
  } else if (user.rights.includes(UserDto.RightsEnum.AccessOrgSap)) {
    return ['/school/skills-assessment'];
  }
  return ['/school/personalData'];
}
