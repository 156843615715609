import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordRequestPayload } from '@shared/login/components/reset-password-request/reset-password-request-payload';
import { ValidationService } from '@shared/providers/validation.service';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../../../util/validation/validation-error-details';

@Component({
  selector: 'sx-reset-password-request-view',
  templateUrl: 'reset-password-request.view.html',
})
export class ResetPasswordRequestViewComponent implements AfterViewInit {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  getErrorDetails: ValidationErrorDetailsGetter;

  @Output()
  requestReset = new EventEmitter<ResetPasswordRequestPayload>();

  @ViewChild('emailField', { static: true })
  emailField: ElementRef;

  constructor(
    private translate: TranslateService,
    private validation: ValidationService,
  ) {
    this.getErrorDetails = validationErrorDetails(this.form, translate);
  }

  ngAfterViewInit() {
    this.setFocus();
  }

  onRequestReset() {
    this.validation.validateAndThen(this.form, (value) => this.requestReset.emit(value));
  }

  private setFocus(): void {
    setTimeout(() => {
      this.emailField.nativeElement.focus();
    });
  }
}
