import { Component, Input, OnInit } from '@angular/core';
import { ResponsiveService } from '@shared/responsive/responsive.service';
import { Destroyable } from 'src/app/util/destroyable';

@Component({
  selector: 'sx-dialog-base-layout',
  templateUrl: './dialog-base-layout.component.html',
  styleUrls: ['./dialog-base-layout.component.scss'],
})
export class DialogBaseLayoutComponent extends Destroyable implements OnInit {
  @Input()
  matIconName = 'feed';

  @Input()
  titleLabel: string;

  @Input()
  subTitle = '';

  @Input()
  omitSubTitleOnMobile = false;

  @Input()
  omitSubTitle2OnMobile = false;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'md';

  isPhone = false;

  constructor(private responsive: ResponsiveService) {
    super();
  }

  ngOnInit(): void {
    this.responsive.isPhone$
      .pipe(this.takeUntilDestroyed())
      .subscribe((isPhone) => (this.isPhone = isPhone));
  }

  get maxWidth(): string {
    switch (this.size) {
      case 'sm':
        return '512px';
      case 'md':
        return '768px';
      case 'lg':
        return '1024px';
      default:
        return '768px';
    }
  }
}
