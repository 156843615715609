/**
 * CanidateApi
 * Swiss Exams - Microservices - Candidate Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserExamReschedulingRequestDto } from './userExamReschedulingRequestDto';
import { UserExamDocumentDto } from './userExamDocumentDto';
import { UserExamModuleDto } from './userExamModuleDto';
import { UserExamSubtypeDto } from './userExamSubtypeDto';


export interface UserExamDto { 
    registrationCrmId: string;
    examCrmId: string;
    time: string;
    name: string;
    type: string;
    subType: UserExamSubtypeDto;
    location: string;
    products: Array<string>;
    paid: boolean;
    paymentMethod: number;
    paymentMethodName: string;
    registrationStatus: number;
    registrationStatusName: string;
    schoolName?: string;
    teacherName?: string;
    interviewPartnerName?: string;
    regularRegistrationDeadline?: string;
    entryStatementDeadline?: object;
    modules: Array<UserExamModuleDto>;
    resultRelease?: string;
    documents: Array<UserExamDocumentDto>;
    invoiceSent: boolean;
    invoiceUrl?: string;
    score?: number;
    eroGenerationStatus?: UserExamDto.EroGenerationStatusEnum;
    eroGenerationMessage?: string;
    generatedEro?: string;
    reschedulingTasks: Array<UserExamReschedulingRequestDto>;
    isRescheduling: boolean;
    payNowStatus?: UserExamDto.PayNowStatusEnum;
    interviewPartnerFirstName?: string;
    interviewPartnerLastName?: string;
    cambridgeResultId?: string;
    cambridgeResultPw?: string;
}
export namespace UserExamDto {
    export type EroGenerationStatusEnum = 'Requested' | 'Failed' | 'Generated';
    export const EroGenerationStatusEnum = {
        Requested: 'Requested' as EroGenerationStatusEnum,
        Failed: 'Failed' as EroGenerationStatusEnum,
        Generated: 'Generated' as EroGenerationStatusEnum
    };
    export type PayNowStatusEnum = 'New' | 'Failed' | 'Paid' | 'Transferred';
    export const PayNowStatusEnum = {
        New: 'New' as PayNowStatusEnum,
        Failed: 'Failed' as PayNowStatusEnum,
        Paid: 'Paid' as PayNowStatusEnum,
        Transferred: 'Transferred' as PayNowStatusEnum
    };
}


