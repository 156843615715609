import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InformationDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'sx-information-dialog',
  template: `
    <sx-card-layout-view ngClass="dialog" [label]="data.title || 'Info'">
      <div mat-dialog-content>
        <p [innerHTML]="data.message | translate"></p>
      </div>
      <mat-divider class="dialog__divider"></mat-divider>
      <div class="dialog__actions">
        <button mat-button (click)="onOK()">{{ 'OK' | translate }}</button>
      </div>
    </sx-card-layout-view>
  `,
})
export class InformationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InformationDialogData,
    private dialogRef: MatDialogRef<InformationDialogComponent>,
  ) {}

  onOK() {
    this.dialogRef.close();
  }
}
