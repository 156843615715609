/**
 * PaymentApi
 * Swiss Exams - Microservices - Payment Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { InitalPaymentResponseDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PaymentApi {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * LEGACY: Create the payment redirect url and validate the invoice so that the state is Active and the statusReason is New
     * @param tenant The tenant for the payment
     * @param invoiceCrmId The base64 encoded crm id of the invoice
     * @param ngswBypass This header is used to bypass the Service Worker
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initLegacyPayment(tenant: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InitalPaymentResponseDto>;
    public initLegacyPayment(tenant: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InitalPaymentResponseDto>>;
    public initLegacyPayment(tenant: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InitalPaymentResponseDto>>;
    public initLegacyPayment(tenant: string, invoiceCrmId: string, ngswBypass?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling initLegacyPayment.');
        }
        if (invoiceCrmId === null || invoiceCrmId === undefined) {
            throw new Error('Required parameter invoiceCrmId was null or undefined when calling initLegacyPayment.');
        }

        let headers = this.defaultHeaders;
        if (ngswBypass !== undefined && ngswBypass !== null) {
            headers = headers.set('ngsw-bypass', String(ngswBypass));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InitalPaymentResponseDto>(`${this.configuration.basePath}/payment/init/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(invoiceCrmId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create the payment redirect url and validate the invoice so that the state is Active and the statusReason is New
     * @param tenant The tenant for the payment
     * @param contactCrmId The base64 encoded crm id of the contact
     * @param invoiceCrmId The base64 encoded crm id of the invoice
     * @param ngswBypass This header is used to bypass the Service Worker
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initPayment(tenant: string, contactCrmId: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InitalPaymentResponseDto>;
    public initPayment(tenant: string, contactCrmId: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InitalPaymentResponseDto>>;
    public initPayment(tenant: string, contactCrmId: string, invoiceCrmId: string, ngswBypass?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InitalPaymentResponseDto>>;
    public initPayment(tenant: string, contactCrmId: string, invoiceCrmId: string, ngswBypass?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling initPayment.');
        }
        if (contactCrmId === null || contactCrmId === undefined) {
            throw new Error('Required parameter contactCrmId was null or undefined when calling initPayment.');
        }
        if (invoiceCrmId === null || invoiceCrmId === undefined) {
            throw new Error('Required parameter invoiceCrmId was null or undefined when calling initPayment.');
        }

        let headers = this.defaultHeaders;
        if (ngswBypass !== undefined && ngswBypass !== null) {
            headers = headers.set('ngsw-bypass', String(ngswBypass));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InitalPaymentResponseDto>(`${this.configuration.basePath}/payment/init/${encodeURIComponent(String(tenant))}/${encodeURIComponent(String(invoiceCrmId))}/${encodeURIComponent(String(contactCrmId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param paymentTrace The payment trace encoded in base64
     * @param ngswBypass This header is used to bypass the Service Worker
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentControllerNotifyFail(paymentTrace: string, ngswBypass?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public paymentControllerNotifyFail(paymentTrace: string, ngswBypass?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public paymentControllerNotifyFail(paymentTrace: string, ngswBypass?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public paymentControllerNotifyFail(paymentTrace: string, ngswBypass?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (paymentTrace === null || paymentTrace === undefined) {
            throw new Error('Required parameter paymentTrace was null or undefined when calling paymentControllerNotifyFail.');
        }

        let headers = this.defaultHeaders;
        if (ngswBypass !== undefined && ngswBypass !== null) {
            headers = headers.set('ngsw-bypass', String(ngswBypass));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/payment/notify/fail/${encodeURIComponent(String(paymentTrace))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param paymentTrace The payment trace encoded in base64
     * @param ngswBypass This header is used to bypass the Service Worker
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentControllerNotifySuccess(paymentTrace: string, ngswBypass?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public paymentControllerNotifySuccess(paymentTrace: string, ngswBypass?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public paymentControllerNotifySuccess(paymentTrace: string, ngswBypass?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public paymentControllerNotifySuccess(paymentTrace: string, ngswBypass?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (paymentTrace === null || paymentTrace === undefined) {
            throw new Error('Required parameter paymentTrace was null or undefined when calling paymentControllerNotifySuccess.');
        }

        let headers = this.defaultHeaders;
        if (ngswBypass !== undefined && ngswBypass !== null) {
            headers = headers.set('ngsw-bypass', String(ngswBypass));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/payment/notify/success/${encodeURIComponent(String(paymentTrace))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pay for the registration
     * @param registrationCrmId The crm id of the registration
     * @param ngswBypass This header is used to bypass the Service Worker
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registrationPayment(registrationCrmId: string, ngswBypass?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InitalPaymentResponseDto>;
    public registrationPayment(registrationCrmId: string, ngswBypass?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InitalPaymentResponseDto>>;
    public registrationPayment(registrationCrmId: string, ngswBypass?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InitalPaymentResponseDto>>;
    public registrationPayment(registrationCrmId: string, ngswBypass?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (registrationCrmId === null || registrationCrmId === undefined) {
            throw new Error('Required parameter registrationCrmId was null or undefined when calling registrationPayment.');
        }

        let headers = this.defaultHeaders;
        if (ngswBypass !== undefined && ngswBypass !== null) {
            headers = headers.set('ngsw-bypass', String(ngswBypass));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InitalPaymentResponseDto>(`${this.configuration.basePath}/payment/registration/${encodeURIComponent(String(registrationCrmId))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
