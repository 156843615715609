import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faCalendarDay,
  faExternalLinkAlt,
  faInfoCircle,
  faPencilAlt,
  faSchool,
  faTrashAlt,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUserTie,
  faCheckDouble,
  faChevronRight,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';

const IMPORT_AND_EXPORT = [FontAwesomeModule];

@NgModule({
  declarations: [],
  imports: [...IMPORT_AND_EXPORT],
  exports: [...IMPORT_AND_EXPORT],
})
export class FontawesomeModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(
      faUserTie,
      faUserFriends,
      faSchool,
      faUsers,
      faExternalLinkAlt,
      faInfoCircle,
      faCalendarDay,
      faUserPlus,
      faPencilAlt,
      faTrashAlt,
      faCheckDouble,
      faChevronRight,
      faBuilding,
    );
  }
}
