import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthStateService } from '../../core/auth/auth-state.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isLoggedInForOrg } from '../../util/is-logged-in-for-org';
import { getRouteForAfterLogin } from '../../util/after-org-login';

@Injectable({ providedIn: 'root' })
export class OrgGuard {
  constructor(
    private router: Router,
    private auth: AuthStateService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const right = route.data.requiredRight;

    return this.auth.state$.pipe(
      map((s) =>
        isLoggedInForOrg(s.user, right)
          ? true
          : this.router.createUrlTree(getRouteForAfterLogin(s.user)),
      ),
    );
  }
}
