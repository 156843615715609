import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordMatches(fn: () => AbstractControl): ValidatorFn {
  return (control) => {
    const password = fn();
    if (!password) {
      return;
    }
    const valid = password && password.value === control.value;
    return valid
      ? null
      : {
          passwordDoesNotMatch: 'does not match confirmation field',
        };
  };
}
