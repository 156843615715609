<mat-card appearance="raised" class="public-card">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary">vpn_key</mat-icon>
      <span>{{ label }}</span>
    </mat-card-title>
    <mat-card-subtitle
      [innerHTML]="'Passwordless Login Info Description' | translate"
    ></mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="public-card__content">
    <mat-vertical-stepper
      [linear]="true"
      [selectedIndex]="step"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-step [label]="'Enter Email' | translate" [stepControl]="requestForm">
        <form [formGroup]="requestForm" (ngSubmit)="onSendEmail()" class="public-card__form">
          <mat-form-field>
            <mat-label>{{ 'Email' | translate }}</mat-label>
            <input #emailField matInput type="email" formControlName="email" />
            <mat-error *ngIf="validateRequest('email', 'Email') as details">
              {{ details.message | translate : details.params }}
            </mat-error>
          </mat-form-field>

          <!-- <mat-form-field>
            <mat-label>{{ 'Date of birth' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="picker"
                   formControlName="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <sx-action-button
            class="public-card__action-button"
            [label]="'Request Verification Code' | translate"
            [type]="'submit'"
          ></sx-action-button>
        </form>
      </mat-step>
      <mat-step [label]="'Enter Verification Code' | translate" [stepControl]="verificationForm">
        <form [formGroup]="verificationForm" (ngSubmit)="onLogin()" class="public-card__form">
          <mat-form-field>
            <mat-label>{{ 'Verification Code' | translate }}</mat-label>
            <input #codeField matInput formControlName="code" />
            <mat-error *ngIf="validateVerification('code', 'Verification Code') as details">
              {{ details.message | translate : details.params }}
            </mat-error>
          </mat-form-field>
          <sx-action-button [label]="'Log in' | translate" [type]="'submit'"></sx-action-button>
        </form>
      </mat-step>
    </mat-vertical-stepper>

    <mat-card-actions class="public-card__actions">
      <sx-router-link-button
        [label]="'Organization and School Access' | translate"
        [type]="'button'"
        [small]="true"
        [link]="'../school'"
        class="public-card__action-button"
      ></sx-router-link-button>
      <sx-router-link-button
        [label]="'Staff Access Title' | translate"
        [type]="'button'"
        [small]="true"
        [link]="'../staff'"
        class="public-card__action-button"
      ></sx-router-link-button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
