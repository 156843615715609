import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { IELTS_TENANT } from '@shared/tenant/ielts.tenant';
import { Tenant } from '@shared/tenant/tenant';
import { TENANTS } from '@shared/tenant/tenant-helpers';
import { TenantService } from '@shared/tenant/tenant.service';
import { Observable, of } from 'rxjs';

/**
 * Extract the tenant and set it in the TenantService
 */
@Injectable()
export class TenantResolver {
  constructor(private tenantService: TenantService) {}

  resolve(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<Tenant | undefined> {
    const tenant = this.findTenant(TENANTS, route.params.tenant) ?? IELTS_TENANT;
    this.tenantService.tenant = tenant;

    return of(tenant);
  }

  findTenant(tenants: Tenant[], query: string): Tenant {
    return tenants.find(({ identifier }: Tenant) => {
      return identifier?.toLowerCase() === query?.toLowerCase();
    });
  }
}
