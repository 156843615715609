import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { touchAndUpdateControl } from '../../util/validation/touch-and-update-control';
import { NotificationService } from '@shared/providers/notification.service';

@Injectable()
export class ValidationService {
  constructor(private notification: NotificationService) {}

  validateAndThen<ValueType = any>(
    control: AbstractControl,
    whenValid: (value: ValueType) => any,
    message: string = 'Your entry is invalid.',
  ) {
    touchAndUpdateControl(control);
    if (!control.valid) {
      this.notification.error(message);
      return;
    }
    whenValid(control.value);
  }
}
