/**
 * sx-ms-school-api
 * Swiss Exams - Microservices - school Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RegistrationDto } from './registrationDto';


export interface BulkRegistrationEntryDto { 
    id: string;
    code: string;
    reservationId: string;
    reservationCrmId: string;
    candidateNumber: number;
    irn?: number;
    registration?: RegistrationDto;
    registrationStatus: BulkRegistrationEntryDto.RegistrationStatusEnum;
    createdAt: string;
    lastSavedAt: string;
    submittedAt?: string;
    registeredAt?: string;
}
export namespace BulkRegistrationEntryDto {
    export type RegistrationStatusEnum = 'None' | 'Draft' | 'Submitted' | 'Registered' | 'Failed';
    export const RegistrationStatusEnum = {
        None: 'None' as RegistrationStatusEnum,
        Draft: 'Draft' as RegistrationStatusEnum,
        Submitted: 'Submitted' as RegistrationStatusEnum,
        Registered: 'Registered' as RegistrationStatusEnum,
        Failed: 'Failed' as RegistrationStatusEnum
    };
}


