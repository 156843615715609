<nav class="nav">
  <div class="nav__context-title">
    <span class="nav__context-title-icon">
      <ng-container *ngIf="context === 'school'">
        <ng-container *ngIf="isSchool$ | async then isSchool else isOrg"></ng-container>
        <ng-template #isSchool>
          <fa-icon [icon]="['fas', 'school']" [fixedWidth]="true"></fa-icon>
        </ng-template>
        <ng-template #isOrg>
          <fa-icon [icon]="['fas', 'building']" [fixedWidth]="true"></fa-icon>
        </ng-template>
      </ng-container>
      <span *ngIf="context === 'staff'">
        <fa-icon [icon]="['fas', 'users']" [fixedWidth]="true"></fa-icon>
      </span>
    </span>
    <span class="nav__context-title-text">{{ contextTitle }}</span>
  </div>

  <mat-list>
    <ng-template ngFor let-item [ngForOf]="items$ | async">
      <ng-template [ngIf]="item.type === 'accordion'">
        <mat-accordion multi>
          <mat-expansion-panel expanded class="mat-elevation-z0 mat-expansion-panel__nav-item">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary" appearance="outline" [fontSet]="item.fontSet">
                  {{ item.icon }}
                </mat-icon>
                <div>{{item.label | translate }}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template ngFor let-child [ngForOf]="item.children">
              <a
                mat-button
                class="nav__item"
                routerLinkActive="active"
                [routerLink]="child.routerLink"
                [queryParams]="child.queryParams"
              >
                <mat-icon class="mat-accordion-list-icon" color="primary" appearance="outline">
                  subdirectory_arrow_right
                </mat-icon>
                <p class="display-inline">{{ child.name | translate }}</p>
              </a>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-template>

      <ng-template [ngIf]="item.type === 'separator'">
        <mat-divider class="nav__divider"></mat-divider>
      </ng-template>

      <ng-template [ngIf]="item.type === 'routerLink'">
        <mat-list-item>
          <a
            mat-button
            [routerLink]="item.routerLink"
            [queryParams]="item.queryParams"
            [ngClass]="' nav__item ' + (item.modifier ? 'nav__item--' + item.modifier : '')"
            routerLinkActive="active"
          >
            <mat-icon color="primary" appearance="outline" [fontSet]="item.fontSet">
              {{ item.icon }}
            </mat-icon>
            <span>{{ item.label | translate }}</span>
          </a>
        </mat-list-item>
      </ng-template>

      <ng-template [ngIf]="item.type === 'url'">
        <mat-list-item>
          <a
            mat-button
            [href]="item.url"
            [target]="item.target"
            [ngClass]="' nav__item ' + (item.modifier ? 'nav__item--' + item.modifier : '')"
          >
            <mat-icon color="primary" appearance="outline" [fontSet]="item.fontSet">
              {{ item.icon }}
            </mat-icon>
            <span>{{ item.label | translate }}</span>
          </a>
        </mat-list-item>
      </ng-template>

      <ng-template [ngIf]="item.type === 'action'">
        <mat-list-item>
          <a
            mat-button
            [ngClass]="' nav__item ' + (item.modifier ? 'nav__item--' + item.modifier : '')"
            href="javascript:void(0)"
            (click)="item.action()"
          >
            <mat-icon color="primary" [fontSet]="item.fontSet">{{ item.icon }}</mat-icon>
            <span>{{ item.label | translate }}</span>
          </a>
        </mat-list-item>
      </ng-template>

      <ng-template [ngIf]="item.type === 'dropdown'">
        <mat-list-item>
          <button
            mat-button
            [ngClass]="' nav__item ' + (item.modifier ? 'nav__item--' + item.modifier : '')"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon color="primary" [fontSet]="item.fontSet">{{ item.icon }}</mat-icon>
            <span>{{ item.label | translate }}</span>
          </button>
          <mat-menu #menu="matMenu">
            <ng-template ngFor let-entry [ngForOf]="item.entries">
              <button mat-menu-item (click)="entry.action()">{{ entry.label | translate }}</button>
            </ng-template>
          </mat-menu>
        </mat-list-item>
      </ng-template>
    </ng-template>
  </mat-list>
</nav>
