import { Component } from '@angular/core';
import { Destroyable } from '../../util/destroyable';
import { CommitPasswordResetPayload } from '@shared/login/components/commit-password-reset/commit-password-reset-payload';
import { AuthApi } from '@generated/auth-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@shared/providers/notification.service';

@Component({
  selector: 'sx-school-reset-password-view',
  template: `
    <sx-commit-password-reset-view
      [initial]="isWelcome"
      (commitReset)="onCommit($event)"
    ></sx-commit-password-reset-view>
  `,
})
export class SchoolCommitPasswordResetViewComponent extends Destroyable {
  private email: string;
  private nonce: string;
  isWelcome: boolean;

  constructor(
    private auth: AuthApi,
    private router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
  ) {
    super();
    const qp = route.snapshot.queryParams;
    this.email = qp.email;
    this.nonce = qp.nonce;
    this.isWelcome = qp.context === 'welcome';
  }

  onCommit(payload: CommitPasswordResetPayload) {
    this.auth
      .resetPassword({
        email: this.email,
        nonce: this.nonce,
        password: payload.password,
      })
      .subscribe(
        () => {
          this.notification.success('Password Reset Success Message');
          this.router.navigate(['/login/school']);
        },
        () => this.notification.error('Password Reset Failed Message'),
      );
  }
}
