import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthApi, OrgSelectionDto } from '@generated/auth-api';
import { TranslateService } from '@ngx-translate/core';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { NotificationService } from '@shared/providers/notification.service';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from 'src/app/util/validation/validation-error-details';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sx-org-user-signup',
  templateUrl: './org-user-signup.component.html',
  styleUrls: ['./org-user-signup.component.scss'],
})
export class OrgUserSignupComponent implements OnInit {
  loading = false;
  organizations: OrgSelectionDto[] = [];

  form = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.minLength(2), Validators.required]),
    lastName: new UntypedFormControl('', [Validators.minLength(2), Validators.required]),
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    organizationIds: new UntypedFormControl([], [Validators.required]),
  });

  validate: ValidationErrorDetailsGetter;

  constructor(
    private translateService: TranslateService,
    private genericDialogs: GenericDialogs,
    private authService: AuthApi,
    private router: Router,
    private notificationService: NotificationService,
    private location: Location,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Swiss Exams Access | Create your account free of charge');
  }

  ngOnInit(): void {
    this.validate = validationErrorDetails(this.form, this.translateService);

    this.authService.getOrgSelection().subscribe((organisations) => {
      this.organizations = organisations;
    });
  }

  onSubmit() {
    let invalid = false;

    Object.keys(this.form.controls).forEach((key) => {
      if (this.form.controls[key].untouched) {
        this.form.controls[key].markAsTouched();
        invalid = true;
      }
    });

    if (invalid || this.form.invalid) {
      this.notificationService.error('Please fix input errors before continuing');
      return;
    }

    this.loading = true;
    this.authService
      .signupAccessOrgUser(this.form.value)
      .pipe(
        catchError(() => {
          this.notificationService.error('Registration Error Toast');
          this.loading = false;
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.notificationService.success('Registration Success Toast');
        this.loading = false;
        this.router.navigateByUrl('/login/school');
      });
  }

  showSchoolNotFoundModal() {
    this.genericDialogs.information('Create Account', 'Create Account Message').subscribe();
  }

  onBackButton() {
    if (!this.loading) {
      this.location.back();
    }
  }
}
