import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDto } from '@generated/auth-api';
import { TranslateService } from '@ngx-translate/core';
import { MaterialIconFontSets } from '@shared/material-icons-font-sets';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { getSaVariants } from '@shared/skills-assessment/skills-assessment-accordion.const';
import { FORMAL_MAPPING } from 'src/app/translation/languages';
import { TranslateLanguageService } from 'src/app/translation/translate-language.service';
import { IS_BROWSER_IE } from 'src/app/util/browser';
import { environment } from 'src/environments/environment';
import { AuthStateService } from '../auth/auth-state.service';
import { AccordionChildren, ActionNavItem, NavItem } from './nav-item';

interface RouteOptions {
  hasExams?: boolean;
  hasAssessments?: boolean;
}

@Injectable()
export class NavService {
  constructor(
    private dialogs: GenericDialogs,
    private authState: AuthStateService,
    private translateService: TranslateService,
    private translateLanguage: TranslateLanguageService,
    private localStorage: LocalStorageService,
    private router: Router,
  ) {}

  getLogoutRedirectTarget(user?: UserDto): string {
    const { School, Staff } = UserDto.ContextEnum;

    if (!user) {
      return '/login/candidate';
    }

    if (user.context === School) {
      return '/login/school';
    }

    if (user.context === Staff) {
      return '/login/staff';
    }

    return '/login/candidate';
  }

  public createNavigation(user: UserDto, hasAssessments: boolean, hasExams: boolean): NavItem[] {
    const { Candidate, School, Staff } = UserDto.ContextEnum;

    let contextSpecificBefore: NavItem[];
    let contextSpecificAfter: NavItem[] = [];

    if (!user) {
      return [];
    }

    if (user.context === Candidate) {
      contextSpecificBefore = this.handleCandidateRoutes(user, { hasAssessments, hasExams });
      contextSpecificAfter = this.appendContextSpecificAfterRoutes(Candidate);
    } else if (user.context === School) {
      contextSpecificBefore = this.handleSchoolRoutes(user);
      contextSpecificAfter = this.appendContextSpecificAfterRoutes(School);
    } else if (user.context === Staff) {
      contextSpecificBefore = this.handleStaffRoutes();
    }

    const navigation: NavItem[] = this.removeUnauthorizedNavItems(
      user,
      ...contextSpecificBefore,
      this.appendLanguageNavItem(),
      ...contextSpecificAfter,
      ...this.appendLogoutSection(),
    );

    return navigation;
  }

  onLangChange(lang: string) {
    this.translateLanguage.lang = lang;
    this.localStorage.put('lang', lang);
  }

  onLogout() {
    const { user } = this.authState.state;
    this.authState.onLogout();
    this.router.navigateByUrl(this.getLogoutRedirectTarget(user));
  }

  onShowCandidateCambridgeResults() {
    this.dialogs.externalLink({
      title: 'Cambridge Result Website',
      // Be aware, the whole message text is representing the translation token
      message:
        'Note: This link will take you to the global Cambridge Assessment English results website. Your "Swiss Exams Access" login will NOT be valid there. Please use your login for Cambridge Assessment English - "Results Service for Candidates". More information about your login details can be found on your Online Result Letter.',
      url: environment.candidateCambrigdeResultUrl,
    });
  }

  onShowSchoolCambridgeResults() {
    this.dialogs.externalLink({
      title: 'Cambridge Result Website',
      // Be aware, the whole message text is representing the translation token
      message:
        'Note: This link will take you to the global Cambridge Assessment results website. Your "Swiss Exams Access" login will NOT be valid there. Please use your login for Cambridge Assessment - "Preparation Centre Results Online".',
      url: environment.schoolCambrigdeResultUrl,
    });
  }

  private removeUnauthorizedNavItems(user: UserDto, ...navItems: NavItem[]): NavItem[] {
    if (!navItems?.length) {
      return [];
    }

    return navItems.filter(
      (item) => !item.requiredRight || user.rights.includes(item.requiredRight),
    );
  }

  private appendLanguageNavItem(): NavItem {
    const languages: ActionNavItem[] = environment.languages.map((lang) => ({
      type: 'action',
      label: this.translateService.instant('Languages.' + lang),
      icon: 'none',
      action: () => this.onLangChange(lang),
    }));

    const translatedNav = this.translateService.instant(
      'Languages.' + FORMAL_MAPPING[this.translateService.currentLang] ||
        this.translateService.currentLang,
    );

    const navItem = {
      type: 'dropdown',
      label: translatedNav,
      icon: 'language',
      entries: languages,
    };

    return navItem as NavItem;
  }

  private appendLogoutSection(): NavItem[] {
    return [
      {
        type: 'separator',
      },
      {
        type: 'action',
        label: 'Logout',
        icon: 'clear',
        action: () => this.onLogout(),
      },
    ];
  }

  private handleCandidateRoutes(user: UserDto, options?: RouteOptions): NavItem[] {
    const candidateRoutes: NavItem[] = [];

    const examSubNavElements: AccordionChildren[] = [];

    if (options?.hasExams) {
      examSubNavElements.push({
        name: 'My Exams',
        routerLink: ['candidate', 'exams'],
      });
    }

    if (options?.hasAssessments) {
      examSubNavElements.push({
        name: 'Linguaskill Tests',
        routerLink: ['candidate', 'skills-assessment', 'linguaskill'],
      });
    }

    candidateRoutes.push({
      type: 'accordion',
      label: 'My Exams / Tests',
      icon: 'event_note',
      children: examSubNavElements,
    });

    candidateRoutes.push(
      {
        type: 'routerLink',
        label: 'Personal Data',
        icon: 'fingerprint',
        routerLink: ['/candidate/contact'],
      },
      {
        type: 'separator',
      },
    );

    return candidateRoutes;
  }

  private handleSchoolRoutes(user: UserDto): NavItem[] {
    return [
      {
        type: 'routerLink',
        label: 'Reservations',
        icon: 'description',
        fontSet: IS_BROWSER_IE ? undefined : MaterialIconFontSets.outlined,
        routerLink: ['/', 'school', 'reservations'],
        requiredRight: UserDto.RightsEnum.AccessTeacherManageReservations,
      },
      {
        type: 'routerLink',
        label: 'All Registrations',
        icon: 'assignment',
        routerLink: ['/', 'school', 'allRegistrations'],
        requiredRight: UserDto.RightsEnum.AccessTeacherViewRegistrations,
      },
      {
        type: 'accordion',
        label: 'Skills Assessment',
        icon: 'done_all',
        children: getSaVariants(user.context),
        requiredRight: UserDto.RightsEnum.AccessOrgSap,
      },
      {
        type: 'routerLink',
        label: 'Personal Data',
        icon: 'fingerprint',
        routerLink: ['/', 'school', 'personalData'],
      },
      {
        type: 'separator',
      },
      {
        type: 'routerLink',
        label: 'Account',
        icon: 'account_circle',
        routerLink: ['/school/account'],
      },
    ];
  }

  private handleStaffRoutes(): NavItem[] {
    return [
      {
        type: 'routerLink',
        label: 'Availabilities',
        icon: 'date_range',
        routerLink: ['/', 'staff', 'availability'],
      },
      {
        type: 'accordion',
        label: 'Work Assignments',
        icon: 'article',
        children: [
          {
            name: 'Overview',
            routerLink: ['/', 'staff', 'assigned-exams', 'overview'],
          },
          {
            name: 'Past Work Assignments',
            routerLink: ['/', 'staff', 'assigned-exams', 'history'],
          },
        ],
      },
      {
        type: 'routerLink',
        label: 'Payments',
        icon: 'payments',
        routerLink: ['/', 'staff', 'payment', 'overview'],
      },
      // {
      //   type: 'routerLink',
      //   label: 'Exam Reporting',
      //   icon: 'analytics',
      //   routerLink: ['/', 'staff', 'exam-reporting'],
      // },
      // {
      //   type: 'routerLink',
      //   label: 'Training',
      //   icon: 'settings_accessibility',
      //   routerLink: ['/', 'staff', 'staff-training'],
      // },
      {
        type: 'separator',
      },
      {
        type: 'routerLink',
        label: 'Account',
        icon: 'account_circle',
        routerLink: ['/staff/account'],
      },
    ];
  }

  private appendContextSpecificAfterRoutes(userContext: UserDto.ContextEnum): NavItem[] {
    return [
      { type: 'separator' },
      {
        type: 'action',
        label: 'Cambridge Result Website',
        icon: 'link',
        action: this.getContextSpecificCambridgeResultWebsiteAction(userContext),
      },
      {
        type: 'url',
        label: 'Goethe Result Website',
        icon: 'link',
        url: environment.goetheResultUrl,
        target: '_blank',
      },
      {
        type: 'url',
        label: 'TCF Result Website',
        icon: 'link',
        url: environment.tcfResultUrl,
        target: '_blank',
      },
      ...this.getContextSpecificCustomerServicePortalRoute(userContext),
    ];
  }

  private getContextSpecificCustomerServicePortalRoute(
    userContext: UserDto.ContextEnum,
  ): NavItem[] {
    const { Candidate } = UserDto.ContextEnum;

    if (userContext === Candidate) {
      return [
        {
          type: 'separator',
        },
        {
          type: 'url',
          label: this.translateService.instant('Assistance for Candidates'),
          icon: 'help',
          url: environment.customerServicePortalUrl,
          target: '_blank',
        },
      ];
    }

    return [];
  }

  private getContextSpecificCambridgeResultWebsiteAction(userContext: UserDto.ContextEnum) {
    const { Candidate, School } = UserDto.ContextEnum;

    if (userContext === Candidate) {
      return this.onShowCandidateCambridgeResults.bind(this);
    } else if (userContext === School) {
      return this.onShowSchoolCambridgeResults.bind(this);
    } else {
      // Fallback
      return this.onShowSchoolCambridgeResults.bind(this);
    }
  }
}
