<mat-toolbar *isBelowDesktop>
  <mat-toolbar-row *ngIf="showMenu">
    <button mat-icon-button type="button" ngClass="menu-button" (click)="onToggleNav()">
      <mat-icon color="primary">menu</mat-icon>
    </button>
    <div class="brand">
      <img
        class="logo img-responsive"
        src="../assets/images/swiss-exams-access-logo.png"
        alt="Swiss Exams"
      />
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [ngClass]="{ 'no-padding': !showMenu }">
  <mat-sidenav
    *ngIf="showMenu"
    [mode]="mode"
    [opened]="visible"
    [autoFocus]="false"
    (closedStart)="onNavToggled(false)"
    (openedStart)="onNavToggled(true)"
  >
    <header>
      <div class="brand">
        <img
          class="logo img-responsive"
          src="../assets/images/swiss-exams-access-logo.png"
          alt="Swiss Exams"
        />
      </div>
      <sx-nav-view></sx-nav-view>
    </header>
  </mat-sidenav>
  <mat-sidenav-content>
    <main [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
