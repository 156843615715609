import { Component } from '@angular/core';

@Component({
  selector: 'sx-default-payment-view',
  template: `
    <mat-card appearance="raised">
      <mat-card-title>
        {{ 'Something went wrong' | translate }}
      </mat-card-title>
    </mat-card>
  `,
})
export class DefaultPaymentViewComponent {}
