import { Injectable } from '@angular/core';
import { TranslateLanguageService } from './translate-language.service';

@Injectable({
  providedIn: 'root',
})
export class FormalGuard {
  constructor(private translateLanguage: TranslateLanguageService) {}

  canActivate(): boolean {
    this.setFormality();
    return true;
  }

  canActivateChild(): boolean {
    this.setFormality();
    return true;
  }

  private setFormality() {
    this.translateLanguage.formal = true;
  }
}
