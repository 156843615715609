<mat-card appearance="raised" class="public-card">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary">vpn_key</mat-icon>
      <span>{{ (initial ? 'Set Password' : 'Reset Password') | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle
      [innerHTML]="(initial ? 'Set Password Info Description' : 'Reset Password Info Description') | translate"
    ></mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="public-card__content">
    <form [formGroup]="form" (ngSubmit)="onResetPassword()" class="public-card__form">
      <mat-form-field>
        <mat-label>{{ 'Password' | translate }}</mat-label>
        <input #passwordField matInput type="password" formControlName="password" />
        <mat-hint>
          {{ 'Needs to be at least $length characters long.' | translate : { length: 8 } }}
        </mat-hint>
        <mat-error *ngIf="getErrorDetails('password', 'Password') as details">
          {{ details.message | translate : details.params }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
        <input matInput type="password" formControlName="passwordConfirmation" />
        <mat-hint>{{ 'Both passwords need to match.' | translate }}</mat-hint>
        <mat-error
          *ngIf="getErrorDetails('passwordConfirmation', 'Password Confirmation') as details"
        >
          {{ details.message | translate : details.params }}
        </mat-error>
      </mat-form-field>

      <sx-action-button
        [label]="'Set New Password' | translate"
        [type]="'submit'"
        class="public-card__action-button"
      ></sx-action-button>
    </form>
  </mat-card-content>
</mat-card>
