import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getAs<T>(key: string, defaultValue?: T): T | undefined {
    const value = localStorage.getItem(key);
    return value !== undefined ? <T>JSON.parse(value) : defaultValue;
  }

  put(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  clear(key: string) {
    localStorage.removeItem(key);
  }
}
