<input matInput type="text" [value]="value" class="sx-copy-to-clipboard__input" readonly />
<sx-icon-button
  [cdkCopyToClipboard]="value"
  [label]="label"
  [title]="tooltip"
  [icon]="'content_copy'"
  [small]="true"
  class="sx-copy-to-clipboard__button"
  (click)="onClick()"
></sx-icon-button>
