import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TicketLoginRequestDto } from '@generated/auth-api';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@shared/providers/validation.service';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../../../util/validation/validation-error-details';

@Component({
  selector: 'sx-passwordless-login-view',
  templateUrl: 'passwordless-login.view.html',
})
export class PasswordlessLoginViewComponent implements AfterViewInit {
  @Input() label: string;
  @Input() step = 0;

  @Output() sendEmail = new EventEmitter<string>();
  @Output() login = new EventEmitter<TicketLoginRequestDto>();
  @Output() loggedIn = new EventEmitter<any>();

  @ViewChild('emailField', { static: true }) emailField: ElementRef;
  @ViewChild('codeField', { static: true }) codeField: ElementRef;

  requestForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    // 'dateOfBirth': new FormControl('', [Validators.required])
  });

  verificationForm = new UntypedFormGroup({
    code: new UntypedFormControl('', Validators.required),
  });

  validateRequest: ValidationErrorDetailsGetter;
  validateVerification: ValidationErrorDetailsGetter;

  constructor(
    private translate: TranslateService,
    private validation: ValidationService,
  ) {
    this.validateRequest = validationErrorDetails(this.requestForm, translate);
    this.validateVerification = validationErrorDetails(this.verificationForm, translate);
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  onSelectionChange(event): void {
    const activeIndex = event.selectedIndex;
    this.setFocus(activeIndex);
  }

  onSendEmail() {
    this.validation.validateAndThen(this.requestForm, async () =>
      this.sendEmail.emit(this.requestForm.get('email').value),
    );
  }

  onLogin() {
    this.validation.validateAndThen(this.verificationForm, async () => {
      const email = this.requestForm.get('email').value;
      const nonce = this.verificationForm.get('code').value;
      this.login.emit({ email, nonce });
    });
  }

  private setFocus(activeIndex?: number): void {
    setTimeout(() => {
      if (activeIndex === 1) {
        this.codeField.nativeElement.focus();
      } else {
        this.emailField.nativeElement.focus();
      }
    });
  }
}
