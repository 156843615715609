import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidateLoginViewComponent } from './candidate-login/candidate-login.view';
import { SchoolLoginViewComponent } from './school-login/school-login.view';
import { PublicFrameViewComponent } from '@shared/public-frame/public-frame.view';
import { SchoolResetPasswordViewComponent } from './school-login/school-reset-password.view';
import { SchoolCommitPasswordResetViewComponent } from './school-login/school-commit-password-reset.view';
import { CanActivateCommitPasswordResetGuard } from '@shared/login/components/commit-password-reset/can-activate-commit-password-reset.guard';
import { CanActivatePublicViewGuard } from '../core/can-activate-public-view.guard';
import { HomeViewComponent } from './home/home.view';
import { FormalGuard } from '../translation/formal.guard';
import { InformalGuard } from '../translation/informal.guard';
import { AttachmentViewComponent } from './attachment/attachment.view';
import { OrgUserSignupComponent } from '@shared/login/components/org-user-signup/org-user-signup.component';
import { StaffLoginComponent } from './staff-login/staff-login.component';
import { StaffInitialPasswortComponent } from './staff-initial-passwort/staff-initial-passwort.component';
import { PaymentViewComponent } from './payment/payment.view';
import { SuccessPaymentViewComponent } from './payment/success-payment.view';
import { FailPaymentViewComponent } from './payment/fail-payment.view';
import { PaymentFrameViewComponent } from './payment/payment-frame.view';
import { TenantResolver } from './payment/tenant.resolver';
import { DefaultPaymentViewComponent } from './payment/default.view';

const ROUTES: Routes = [
  {
    path: 'attachments/:attachmentId',
    component: AttachmentViewComponent,
  },
  {
    path: 'payment',
    component: PaymentFrameViewComponent,
    children: [
      {
        path: 'init/:tenant/:invoiceCrmId',
        component: PaymentViewComponent,
        resolve: { tenant: TenantResolver },
      },
      {
        path: 'init/:tenant/:invoiceCrmId/:contactCrmId',
        component: PaymentViewComponent,
        resolve: { tenant: TenantResolver },
      },
      {
        path: 'fail/:tenant/:paymentTraceId',
        component: FailPaymentViewComponent,
        resolve: { tenant: TenantResolver },
      },
      {
        path: 'success/:tenant/:paymentTraceId',
        component: SuccessPaymentViewComponent,
        resolve: { tenant: TenantResolver },
      },
      {
        path: '**',
        component: DefaultPaymentViewComponent,
        resolve: { tenant: TenantResolver },
      },
    ],
  },
  {
    path: '',
    component: PublicFrameViewComponent,
    canActivate: [CanActivatePublicViewGuard],
    children: [
      {
        path: 'home',
        canActivate: [FormalGuard],
        component: HomeViewComponent,
      },
      {
        path: 'login',
        children: [
          {
            path: 'candidate',
            canActivate: [InformalGuard],
            component: CandidateLoginViewComponent,
          },
          {
            path: 'school',
            component: SchoolLoginViewComponent,
            canActivate: [FormalGuard],
          },
          {
            path: 'staff',
            component: StaffLoginComponent,
            canActivate: [FormalGuard],
          },
          {
            path: '',
            redirectTo: 'candidate',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'reset',
        children: [
          {
            path: 'initiate',
            canActivate: [FormalGuard],
            component: SchoolResetPasswordViewComponent,
          },
          {
            path: 'commit',
            component: SchoolCommitPasswordResetViewComponent,
            canActivate: [CanActivateCommitPasswordResetGuard, FormalGuard],
          },
          {
            path: 'staff',
            component: StaffInitialPasswortComponent,
            canActivate: [CanActivateCommitPasswordResetGuard, FormalGuard],
          },
        ],
      },
      {
        path: 'signup',
        children: [
          {
            path: 'access-org-user',
            canActivate: [FormalGuard],
            component: OrgUserSignupComponent,
          },
        ],
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
