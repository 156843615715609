<div class="container">
  <mat-card appearance="raised" class="public-card mat-card">
    <mat-card-header>
      <mat-card-title>
        <mat-icon class="margin-right-5" color="primary">account_circle</mat-icon>
        <span>{{ 'Registration Org User Title' | translate }}</span>
      </mat-card-title>
      <mat-card-subtitle>
        {{ 'Registration Org User Subtitle' | translate }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="public-card__content">
      <form [formGroup]="form" class="public-card__form" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <mat-label>{{ 'First Name' | translate }}</mat-label>
          <input matInput type="text" formControlName="firstName" />
          <mat-error *ngIf="validate('firstName', 'First Name') as details">
            {{ details.message | translate: details.params }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'Last Name' | translate }}</mat-label>
          <input matInput type="text" formControlName="lastName" />
          <mat-error *ngIf="validate('lastName', 'Last Name') as details">
            {{ details.message | translate: details.params }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error *ngIf="validate('email', 'Email') as details">
            {{ details.message | translate: details.params }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'Organization' | translate }}</mat-label>
          <mat-select multiple formControlName="organizationIds">
            <mat-option *ngFor="let item of organizations" [value]="item.crmId">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="validate('organizationIds', 'Organization') as details">
            {{ details.message | translate: details.params }}
          </mat-error>
        </mat-form-field>
        <sx-action-button
          [loading]="loading"
          [label]="'Submit Registration' | translate"
          [type]="'submit'"
          class="public-card__action-button"
        ></sx-action-button>
        <sx-action-button
          [disabled]="loading"
          [label]="'Back' | translate"
          [type]="'button'"
          [icon]="'chevron_left'"
          class="public-card__action-button"
          (click)="onBackButton()"
        ></sx-action-button>
      </form>
    </mat-card-content>
    <sx-action-button
      [label]="'Organization Not Found' | translate"
      [type]="'button'"
      [small]="true"
      class="public-card__action-button"
      (action)="showSchoolNotFoundModal()"
    ></sx-action-button>
  </mat-card>
</div>
