import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class StripTranslateHttpLoader extends TranslateHttpLoader {
  getTranslation(lang: string): Observable<Object> {
    return super.getTranslation(lang).pipe(map(stripEmptyTranslations));
  }
}

function stripEmptyTranslations(object: Object | string) {
  if (typeof object === 'string') {
    return object.trim() === '' ? undefined : object;
  }
  return Object.keys(object).reduce(
    (acc, key) => ({
      ...acc,
      [key]: stripEmptyTranslations(object[key]),
    }),
    {},
  );
}
