import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'sx-link-button',
  templateUrl: './link-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LinkButtonComponent implements OnInit {
  @HostBinding('class') class = 'sx-action-button';

  @Input() label: string;
  @Input() type = 'button';
  @Input() small = false;
  @Input() isExternalLink = false;

  @Input() set url(url: string) {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  sanitizedUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.small) {
      this.class += ' small';
    }
  }
}
