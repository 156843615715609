import { NgModule } from '@angular/core';
import { IsDesktopDirective } from './directives/is-desktop.directive';
import { ResponsiveService } from './responsive.service';
import { IsTabletDirective } from './directives/is-tablet.directive';
import { IsPhoneDirective } from './directives/is-phone.directive';
import { IsBelowDesktopDirective } from './directives/is-below-desktop.directive';
import { IsAbovePhoneDirective } from './directives/is-above-phone.directive';

const DECLARE_AND_EXPORT = [
  IsPhoneDirective,
  IsTabletDirective,
  IsDesktopDirective,
  IsBelowDesktopDirective,
  IsAbovePhoneDirective,
];

@NgModule({
  providers: [ResponsiveService],
  declarations: [...DECLARE_AND_EXPORT],
  exports: [...DECLARE_AND_EXPORT],
})
export class ResponsiveModule {}
