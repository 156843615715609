import { ALL_PAYMENT_METHODS, Tenant, TenantIdentifier } from '@shared/tenant/tenant';
import { PaymentDto } from '@generated/school-api/model/paymentDto';

export const CAMBRIDGE_TENANT: Tenant = {
  identifier: TenantIdentifier.Cambridge,
  supportedCountries: ['ch', 'de', 'at', 'fl'],
  bulkRegistrationPaymentMethods: ALL_PAYMENT_METHODS.filter(
    (m) => m !== PaymentDto.MethodEnum.CreditCardPostcard,
  ),
  termsAndConditions: [
    {
      label: 'I have read and understood the "Terms and Conditions for candidates".',
      url: 'https://cambridge-exams.ch/sites/default/files/cambridge_terms_and_conditions.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I confirm that I am in agreement that my photo is taken on the day of my Speaking Test for security reasons.',
      url: 'https://cambridge-exams.ch/sites/default/files/consent_form_2016.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test).',
      url: 'https://cambridge-exams.ch/exams/ID.php',
      openInNewTab: true,
    },
  ],
  logo: {
    src: '../../../assets/cambridge/platinum_centre.png',
    alt: 'Cambridge Logo',
  },
};
