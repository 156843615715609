/**
 * CanidateApi
 * Swiss Exams - Microservices - Candidate Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactDto } from './contactDto';


export interface ContactRequestDto { 
    contactId: string;
    requestType: ContactRequestDto.RequestTypeEnum;
    language: string;
    updatedContact?: ContactDto;
}
export namespace ContactRequestDto {
    export type RequestTypeEnum = 'ContactChange';
    export const RequestTypeEnum = {
        ContactChange: 'ContactChange' as RequestTypeEnum
    };
}


