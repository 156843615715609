import { Component, Input, HostBinding, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '@shared/providers/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sx-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CopyToClipboardComponent {
  @HostBinding('class') class = 'sx-copy-to-clipboard';

  @Input() value: string;
  @Input() label: string;
  @Input() tooltip: string;

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
  ) {}

  onClick() {
    this.notification.info(this.translate.instant('Value copied to clipboard'));
  }
}
