import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { PublicModule } from './public/public.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiModule as Auth, Configuration as AuthConfig } from '@generated/auth-api';
import { ApiModule as Candidate, Configuration as CandidateConfig } from '@generated/candidate-api';
import { ApiModule as Payment, Configuration as PaymentConfig } from '@generated/payment-api';
import { ApiModule as School, Configuration as SchoolConfig } from '@generated/school-api';
import { ApiModule as SkillsAssessment, Configuration as SaConfig } from '@generated/sa-api';
import { ApiModule as Staff, Configuration as StaffConfig } from '@generated/staff-api';
import { AppInitializer, appInitializerFactory } from './app.initializer';
import { ApiService } from './apis/api.service';
import { AuthGuard } from './core/auth/auth.guard';
import { CanActivatePublicViewGuard } from './core/can-activate-public-view.guard';
import { RequiresContextCanActivateGuard } from './core/requires-context-can-activate.guard';
import { NavViewComponent } from './core/nav/nav.view';
import { StripTranslateHttpLoader } from '@shared/providers/strip-translate-http-loader';
import { BackendInterceptor } from './core/backend.interceptor';
import { JwtRefreshInterceptor } from './core/jwt-refresh.interceptor';
import { DownloadInterceptor } from './core/download.interceptor';
import { LoadingService } from './core/loading/loading.service';
import { TranslateLanguageService } from './translation/translate-language.service';
import { FormalGuard } from './translation/formal.guard';
import { InformalGuard } from './translation/informal.guard';
import { FeatureProfilesService } from './core/feature-profiles.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  CANDIDATE_LINGUASKILL_FEATURE,
  CANDIDATE_EXAMS_FEATURE,
  candidateExamsReducer,
  candidateLinguaskillReducer,
  coreEffects,
} from './core/store';
import { NavService } from './core/nav/nav.service';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const AUTH_CONFIG: AuthConfig = new AuthConfig();

export function authConfigFactory() {
  return AUTH_CONFIG;
}

export const CANDIDATE_CONFIG: CandidateConfig = new CandidateConfig();

export function candidateConfigFactory() {
  return CANDIDATE_CONFIG;
}

export const PAYMENT_CONFIG: PaymentConfig = new PaymentConfig();

export function paymentConfigFactory() {
  return PAYMENT_CONFIG;
}

export const SCHOOL_CONFIG: SchoolConfig = new SchoolConfig();

export function schoolConfigFactory() {
  return SCHOOL_CONFIG;
}

export const SA_CONFIG: SaConfig = new SaConfig();

export function saConfigFactory() {
  return SA_CONFIG;
}

export const STAFF_CONFIG: StaffConfig = new StaffConfig();

export function staffConfigFactory() {
  return STAFF_CONFIG;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new StripTranslateHttpLoader(http, './assets/i18n/', '.json');
}

const SentryService = {
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler({
    showDialog: false,
  }),
};

const SentryTracerService = {
  provide: Sentry.TraceService,
  deps: [Router],
};

@NgModule({
  declarations: [AppComponent, NavViewComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    Auth.forRoot(authConfigFactory),
    Candidate.forRoot(candidateConfigFactory),
    School.forRoot(schoolConfigFactory),
    SkillsAssessment.forRoot(saConfigFactory),
    Staff.forRoot(staffConfigFactory),
    Payment.forRoot(paymentConfigFactory),
    SharedModule,
    PublicModule,
    StoreModule.forRoot({
      [CANDIDATE_LINGUASKILL_FEATURE]: candidateLinguaskillReducer,
      [CANDIDATE_EXAMS_FEATURE]: candidateExamsReducer,
    }),
    EffectsModule.forRoot(coreEffects),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    NavService,
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitializer],
      multi: true,
    },
    BackendInterceptor,
    JwtRefreshInterceptor,
    DownloadInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: DownloadInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtRefreshInterceptor, multi: true },
    LoadingService,
    ApiService,
    FeatureProfilesService,
    { provide: AuthConfig, useValue: AUTH_CONFIG },
    { provide: CandidateConfig, useValue: CANDIDATE_CONFIG },
    { provide: SchoolConfig, useValue: SCHOOL_CONFIG },
    { provide: SaConfig, useValue: SA_CONFIG },
    AuthGuard,
    CanActivatePublicViewGuard,
    RequiresContextCanActivateGuard,
    TranslateLanguageService,
    FormalGuard,
    InformalGuard,
    SentryService,
    SentryTracerService,
    ...coreEffects,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
