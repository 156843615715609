import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDto } from '@generated/auth-api';
import { TranslateService } from '@ngx-translate/core';
import { LoginPayload } from '@shared/login/components/username-password-login/login-payload';
import { ValidationService } from '@shared/providers/validation.service';
import { ListItem } from '../../../../util/list-item';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../../../util/validation/validation-error-details';

@Component({
  selector: 'sx-username-password-login-view',
  templateUrl: 'username-password-login.view.html',
})
export class UsernamePasswordLoginViewComponent implements AfterViewInit {
  scopeTitle?: string;
  scope?: ListItem[];

  @Input()
  label: string;

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', Validators.required),
  });

  @Input()
  resetRouterLink?: string[];

  @Input()
  // we set school context as default to keep the backwards compatibility
  context: UserDto.ContextEnum = UserDto.ContextEnum.School;

  @Output()
  login = new EventEmitter<LoginPayload>();

  @ViewChild('emailField', { static: true })
  emailField: ElementRef;

  validate: ValidationErrorDetailsGetter;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private validation: ValidationService,
  ) {
    this.validate = validationErrorDetails(this.form, translate);
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  onLogin() {
    this.validation.validateAndThen(this.form, (value) => this.login.emit(value));
  }

  onCreateAccount(): void {
    this.router.navigateByUrl('/signup/access-org-user');
  }

  onResetPassword() {
    this.router.navigate(this.resetRouterLink);
  }

  private setFocus(): void {
    setTimeout(() => {
      this.emailField.nativeElement.focus();
    });
  }
}
