import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTokenDto, AuthApi, TicketLoginRequestDto } from '@generated/auth-api';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/providers/notification.service';
import { combineLatest } from 'rxjs';
import { AuthStateService } from '../../core/auth/auth-state.service';
import { selectCandidateHasAssessments, selectCandidateHasExams } from '../../core/store';
import { Destroyable } from '../../util/destroyable';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sx-candidate-login-view',
  template: `
    <sx-passwordless-login-view
      [label]="'Candidate Access' | translate"
      [step]="step"
      (sendEmail)="onSendEmail($event)"
      (login)="onLogin($event)"
    ></sx-passwordless-login-view>
  `,
})
export class CandidateLoginViewComponent extends Destroyable implements OnInit {
  step: number = 0;

  constructor(
    private router: Router,
    private authApi: AuthApi,
    private authState: AuthStateService,
    private location: PlatformLocation,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private notification: NotificationService,
    private store: Store,
    private titleService: Title,
  ) {
    super();
    titleService.setTitle('Swiss Exams Access | Login for candidates');
  }

  ngOnInit() {
    const qp = this.route.snapshot.queryParams;
    const { email } = qp;
    const { nonce } = qp;
    if (email && nonce) {
      this.authState.onLogout();
      this.onLogin({ email, nonce });
    } else if (this.authState.state.user) {
      this.router.navigate(['/candidate']);
    }
  }

  onSendEmail(email: string) {
    if (!email) {
      return;
    }
    const port = this.location.port === '80' ? '' : `:${this.location.port}`;
    const loginPath = `${this.location.protocol}//${this.location.hostname}${port}${this.location.pathname}`;
    this.authApi.requestLogin({ email, loginPath, language: this.translate.currentLang }).subscribe(
      /* success */
      () => {
        this.step = 1;
        this.notification.success('Request Login Code succeeded');
      },
      /* error */
      (error) => {
        console.error(error);
        this.notification.error('Request Login Code failed');
      },
    );
  }

  onLogin(request: TicketLoginRequestDto) {
    if (!request.email || !request.nonce) {
      return;
    }
    this.authApi
      .loginWithTicket(request)
      .subscribe(this.onLoggedIn.bind(this), (err) =>
        this.notification.error('Login Code not valid'),
      );
  }

  onLoggedIn(token: AccessTokenDto) {
    const candidateHasAssessments$ = this.store.pipe(select(selectCandidateHasAssessments));
    const candidateHasExams$ = this.store.pipe(select(selectCandidateHasExams));

    this.authState.onTokenAvailable(token);
    this.authApi.me().subscribe(
      (me) => {
        this.authState.onLogin(me);

        combineLatest([candidateHasAssessments$, candidateHasExams$]).subscribe(
          ([hasAssessment, hasExams]) => {
            if (hasAssessment && !hasExams) {
              void this.router.navigate(['/candidate', 'skills-assessment', 'linguaskill']);
            } else {
              void this.router.navigate(['/candidate', 'exams']);
            }
          },
        );
      },
      (err) => {
        console.error('Login Failed: ', err);
        this.notification.error('Login failed');
      },
    );
  }
}
