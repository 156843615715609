import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapWith',
  pure: true,
})
export class MapWithPipe implements PipeTransform {
  transform(value: any, mapper: (value: any) => any): any {
    return mapper(value);
  }
}
