export const DESKTOP_SIZE = 1024;
export const TABLET_SIZE = 600;

const PHONE_UPPER = `(max-width: ${TABLET_SIZE - 1}px)`;
const TABLET_LOWER = `(min-width: ${TABLET_SIZE}px)`;
const TABLET_UPPER = `(max-width: ${DESKTOP_SIZE - 1}px)`;
const DESKTOP_LOWER = `(min-width: ${DESKTOP_SIZE}px)`;

export const MEDIA_QUERY_PHONE = `${PHONE_UPPER}`;
export const MEDIA_QUERY_TABLET = `(${TABLET_LOWER} and ${TABLET_UPPER})`;
export const MEDIA_QUERY_DESKTOP = `${DESKTOP_LOWER}`;
