import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import {
  validationErrorDetails,
  ValidationErrorDetailsGetter,
} from '../../../../util/validation/validation-error-details';
import { ValidationService } from '@shared/providers/validation.service';
import { OrganizationInfoDto, OrgSelectionDto } from '@generated/auth-api';
import { MatSelect } from '@angular/material/select';
import { Location } from '@angular/common';
import { AuthStateService } from '../../../../core/auth/auth-state.service';

@Component({
  selector: 'sx-org-selection',
  templateUrl: 'org-selection.component.html',
  styleUrls: ['./org-selection.component.scss'],
})
export class OrgSelectionComponent implements AfterViewInit {
  @Input()
  label: string;

  @Input()
  orgs: OrgSelectionDto[];

  @Output()
  selectOrg = new EventEmitter<OrganizationInfoDto>();

  @ViewChild('orgSelector', { static: true })
  orgSelector: MatSelect;

  form = new UntypedFormGroup({
    org: new UntypedFormControl('', [Validators.required]),
  });

  validate: ValidationErrorDetailsGetter;

  constructor(
    public translate: TranslateService,
    private genericDialogs: GenericDialogs,
    private router: Router,
    private validation: ValidationService,
    private location: Location,
    private authState: AuthStateService,
  ) {
    this.validate = validationErrorDetails(this.form, translate);
  }

  ngAfterViewInit(): void {
    this.setFocus();
  }

  onSelectOrg() {
    this.validation.validateAndThen(this.form, (value) => this.selectOrg.emit(value.org));
  }

  onBackButton() {
    this.authState.onLogout();
    this.location.back();
  }

  private setFocus(): void {
    setTimeout(() => {
      this.orgSelector.focus();
    });
  }
}
