import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccessTokenDto, AuthApi } from '@generated/auth-api';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { LocalStorageService } from '@shared/providers/local-storage.service';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from './apis/api.service';
import { AuthStateService } from './core/auth/auth-state.service';
import { FeatureProfilesService } from './core/feature-profiles.service';

const TOKEN_KEY = 'auth.token';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authApi: AuthApi,
    private localStorage: LocalStorageService,
    private authState: AuthStateService,
    private dialog: GenericDialogs,
    private features: FeatureProfilesService,
  ) {}

  initialize() {
    return firstValueFrom(
      this.initializeConfig().pipe(
        tap(() => this.initializeAuthStorage()),
        switchMap(() => this.initializeAuth()),
        switchMap(() => this.features.init()),
      ),
    );
  }

  initializeConfig() {
    return this.http.get('/assets/config.json').pipe(
      tap((config: any) => {
        this.apiService.authBasePath = config.authBasePath;
        this.apiService.candidateBasePath = config.candidateBasePath;
        this.apiService.paymentBasePath = config.paymentBasePath;
        this.apiService.schoolBasePath = config.schoolBasePath;
        this.apiService.saBasePath = config.saBasePath;
        this.apiService.staffBasePath = config.staffBasePath;
      }),
    );
  }

  initializeAuth() {
    this.authState.token$.subscribe((token) => (this.apiService.apiKey = token));
    if (!this.authState.state.token) {
      return of(false);
    }
    return this.authApi.me().pipe(
      tap((user) => this.authState.onLogin(user)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authState.onLogout();
        }
        this.dialog.error('Auth service unavailable', 'Auth service unavailable message');
        return of(false);
      }),
    );
  }

  initializeAuthStorage() {
    const t = this.localStorage.getAs<AccessTokenDto>(TOKEN_KEY);
    if (t) {
      this.authState.onTokenAvailable(t);
    }
    this.authState.state$.pipe(map((state) => state.token)).subscribe((token) => {
      if (token) {
        this.localStorage.put(TOKEN_KEY, token);
      } else {
        this.localStorage.clear(TOKEN_KEY);
      }
    });
  }
}

export function appInitializerFactory(initializer: AppInitializer) {
  return () => initializer.initialize();
}
