import { AccordionChildren } from '../../core/nav/nav-item';
import { UserDto } from '@generated/auth-api';
import ContextEnum = UserDto.ContextEnum;

export const defaultSaRoute = 'skills-assessment';

// ============ Declare Children =================
const linguaskill: AccordionChildren = {
  name: 'Linguaskill',
  routerLink: [defaultSaRoute, 'linguaskill'],
};
// =========== End Declare Children ==============

// ========== Add Declared Children ===============
const SA_VARIANTS = [linguaskill];
// ========== End Declared Children ===============

export function getSaVariants(context: ContextEnum) {
  const variants = JSON.parse(JSON.stringify(SA_VARIANTS));
  variants.map((v) => (v.routerLink = [context.toString()].concat(v.routerLink)));

  return variants;
}
