import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

export const REGISTRATIONCRMID_PARAM = 'registrationCrmId';

/**
 * Resolve the registrationCrmId
 */
@Injectable()
export class RegistrationResolver {
  resolve(route: ActivatedRouteSnapshot): string {
    return route.params[REGISTRATIONCRMID_PARAM];
  }
}
