import { Injectable } from '@angular/core';
import { AuthApi, FeatureProfilesDto, FeaturesApi } from '@generated/auth-api';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureProfilesService {
  features: FeatureProfilesDto;

  constructor(private api: FeaturesApi) {}

  init(): Observable<any> {
    if (!environment.loadFeatureFlags) {
      this.features = {
        schoolReservationLinkNew: false,
        schoolReservationLinkBoth: false,
        goetheSchoolReservationLinkNew: false,
        goetheSchoolReservationLinkBoth: false,
        accessCandidateAutomaticReschedule: false,
        examTypeEROTokenSupportList: [],
      };
      return of(this.features);
    }
    return this.api.getFeatureProfiles().pipe(
      tap((features) => {
        this.features = features;
      }),
    );
  }

  public supportsERO(examType: string): boolean {
    return this.features.examTypeEROTokenSupportList.includes(
      examType as FeatureProfilesDto.ExamTypeEROTokenSupportListEnum,
    );
  }
}
