import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'sxTime',
  pure: true,
})
export class TimePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  transform(value: any, displayWhenNotVailable?): any {
    return value ? this.date.transform(value, 'hh:mm') : displayWhenNotVailable || '';
  }
}
