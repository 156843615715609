import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ExamsApi, UserExamsDto } from '@generated/candidate-api';

@Injectable({
  providedIn: 'root',
})
export class ExamsResolver {
  constructor(private examsApi: ExamsApi) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserExamsDto> {
    return this.examsApi.listExams();
  }
}
