import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export function traverseControl(
  control: AbstractControl,
  callback: (control: AbstractControl) => any,
) {
  callback(control);
  if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls)
      .map((key) => control.controls[key])
      .forEach((childControl) => traverseControl(childControl, callback));
  } else if (control instanceof UntypedFormArray) {
    control.controls.forEach((childControl) => traverseControl(childControl, callback));
  }
}
