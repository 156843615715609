import { createReducer, on } from '@ngrx/store';
import { DEFAULT_CANDIDATE_EXAMS_STATE } from './candidate-exams.state';
import { setCandidateHasExams } from './candidate-exams.actions';

export const candidateExamsReducer = createReducer(
  DEFAULT_CANDIDATE_EXAMS_STATE,
  on(setCandidateHasExams, (state, { hasExams }) => ({
    ...state,
    hasExams: hasExams,
  })),
);
