import { PaymentDto } from '@generated/school-api';
import { getAllRecordValues } from '../../util/get-all-record-values';

export const ALL_PAYMENT_METHODS = getAllRecordValues(PaymentDto.MethodEnum);

export type TenantIdentifier = 'Cambridge' | 'Goethe' | 'IELTS';
export const TenantIdentifier = {
  Cambridge: 'Cambridge' as TenantIdentifier,
  Goethe: 'Goethe' as TenantIdentifier,
  IELTS: 'IELTS' as TenantIdentifier,
};

export interface Tenant {
  identifier: TenantIdentifier;
  supportedCountries: string[];
  bulkRegistrationPaymentMethods: PaymentDto.MethodEnum[];
  requiresPlaceOfBirth?: boolean;
  termsAndConditions: Link[];
  logo?: TenantImage;
}

export interface Link {
  label: string;
  url: string;
  openInNewTab?: boolean;
}

export interface TenantImage {
  src: string;
  alt: string;
}
