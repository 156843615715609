import { Component, HostListener, Input } from '@angular/core';
import { filterOutEmptyAndJoin } from '../../util/filter-out-empty-and-join';

@Component({
  selector: 'sx-native-form-view-tracker',
  template: ``,
})
export class NativeFormViewTrackerComponent {
  @Input()
  canDeactivate = true;

  @Input()
  canDeactivateWarningTitle: string;

  @Input()
  canDeactivateWarningMessage: string;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event) {
    const message = this.canDeactivate
      ? undefined
      : filterOutEmptyAndJoin(
          [this.canDeactivateWarningTitle, this.canDeactivateWarningMessage],
          '\n',
        );
    if (message) {
      event.preventDefault();
      event.returnValue = message;
      return message;
    }
  }
}
