import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap } from 'rxjs/operators';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(this.catchError(req, next));
  }

  private catchError(req: HttpRequest<any>, next: HttpHandler, retryDelay = 1000, retries = 3) {
    return catchError((error: HttpErrorResponse) => {
      if ((error.status === 404 || error.status === 0) && retries > 0) {
        return of(true).pipe(
          delay(retryDelay),
          switchMap(() => next.handle(req.clone())),
          this.catchError(req, next, retryDelay, retries - 1),
        );
      }
      return throwError(error);
    });
  }
}
