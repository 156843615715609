<button
  class="sx-action-button"
  mat-button
  [type]="type"
  (click)="onClick($event)"
  [disabled]="loading || disabled"
>
  <mat-icon [color]="iconColor">{{ icon }}</mat-icon>
  {{ label }}
  <span *ngIf="loading">
    <sx-loading-spinner [color]="spinnerColor"></sx-loading-spinner>
  </span>
</button>
