<mat-card appearance="raised" class="card-layout">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary" *ngIf="icon">{{ icon }}</mat-icon>
      <span>{{ label | translate }}</span>
    </mat-card-title>
    <mat-card-subtitle
      *ngIf="!(isPhone && omitSubTitleOnMobile)"
      [innerHTML]="subTitle | translate"
    ></mat-card-subtitle>

    <mat-card-subtitle
      *ngIf="!(isPhone && omitSubTitle2OnMobile)"
      [innerHTML]="subTitle2 | translate"
    ></mat-card-subtitle>
    <mat-card-subtitle>
      <ng-content select="[subTitleContent]"></ng-content>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content ngClass="card-layout__content">
    <ng-content></ng-content>
    <mat-card-actions>
      <div class="card-layout__buttons">
        <ng-content select=".card-layout-buttons"></ng-content>
      </div>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
