import { Injectable } from '@angular/core';
import { SubTypeDto } from '@generated/school-api';
import { GOETHE_TENANT } from '@shared/tenant/goethe.tenant';
import { CAMBRIDGE_TENANT } from '@shared/tenant/cambridge.tenant';
import { Tenant } from '@shared/tenant/tenant';

@Injectable()
export class TenantService {
  tenant: Tenant;
}
