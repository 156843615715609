import { Component, Input } from '@angular/core';
import { ResponsiveService } from '@shared/responsive/responsive.service';
import { Destroyable } from '../../util/destroyable';

@Component({
  selector: 'sx-card-layout-view',
  templateUrl: 'card-layout.view.html',
})
export class CardLayoutViewComponent extends Destroyable {
  @Input()
  icon?: string;

  @Input()
  label: string;

  @Input()
  subTitle: string;

  @Input()
  subTitle2?: string;

  @Input()
  omitSubTitleOnMobile = false;

  @Input()
  omitSubTitle2OnMobile = false;

  isPhone = false;

  constructor(private responsive: ResponsiveService) {
    super();

    responsive.isPhone$
      .pipe(this.takeUntilDestroyed())
      .subscribe((isPhone) => (this.isPhone = isPhone));
  }
}
