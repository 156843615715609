import { Pipe, PipeTransform } from '@angular/core';

/**
 * A helper pipe that pluralizes translation string.
 * This is used for translations where there might be 0, 1 or multiple
 * values.
 *
 * As example, we want to display something with a counter. Ex:
 * - You have 1 new mail
 * - You have 5 new mails
 *
 * Note that the string has a difference (mail vs mails). With this pipe
 * we can make translations strings and append an indicator to tell if
 * there is one or multiple/none values.
 *
 * So the translate string "You have %n new mails" will be converted to
 * "You have %n new mails[n]" or "You have %n new mails[1]", depending
 * on how many items are passed in.
 */
@Pipe({
  name: 'pluralize',
  pure: true,
})
export class PluralizePipe implements PipeTransform {
  transform(value: any, count = 0): any {
    return `${value}[${count === 1 ? '1' : 'n'}]`;
  }
}
