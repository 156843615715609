import { Component, OnInit } from '@angular/core';
import { Tenant } from '@shared/tenant/tenant';
import { TenantService } from '@shared/tenant/tenant.service';

@Component({
  selector: 'sx-payment-frame-view',
  templateUrl: 'payment-frame.view.html',
  styleUrls: ['./payment-frame.view.scss'],
})
export class PaymentFrameViewComponent implements OnInit {
  tenant: Tenant;

  constructor(private tenantService: TenantService) {}

  ngOnInit(): void {
    this.tenant = this.tenantService.tenant;
  }
}
