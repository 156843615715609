import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@shared/dialogs/confirmation-dialog.component';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  CommentDialogComponent,
  CommentDialogResult,
} from '@shared/dialogs/comment-dialog.component';
import { InformationDialogComponent } from '@shared/dialogs/information-dialog.component';
import { ErrorViewComponent } from '@shared/error/error.view';
import { LoadingDialogComponent } from '@shared/dialogs/loading-dialog.component';
import {
  ExternalLinkDialogComponent,
  ExternalLinkDialogData,
} from '@shared/dialogs/external-link-dialog.component';

@Injectable()
export class GenericDialogs {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  confirm(title: string, message: string, footer?: string): Observable<boolean> {
    const footerMessage = footer ? this.translate.instant(footer) : null;

    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(title),
          message: this.translate.instant(message),
          footer: footerMessage,
        },
      })
      .afterClosed()
      .pipe(map((value) => !!value));
  }

  comment(title: string, message?: string): Observable<CommentDialogResult> {
    return this.dialog
      .open(CommentDialogComponent, {
        data: { title, message },
      })
      .afterClosed();
  }

  information(title: string, message: string): Observable<any> {
    return this.dialog
      .open(InformationDialogComponent, {
        data: { title, message },
      })
      .afterClosed();
  }

  error(title: string, message: string) {
    return this.dialog.open(ErrorViewComponent, {
      data: { title, message },
      disableClose: true,
      hasBackdrop: true,
    });
  }

  loading(): MatDialogRef<LoadingDialogComponent> {
    return this.dialog.open(LoadingDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'loading-dialog-panel',
    });
  }

  externalLink(data: ExternalLinkDialogData) {
    return this.dialog
      .open(ExternalLinkDialogComponent, {
        data,
      })
      .afterClosed();
  }
}
