import { createAction, props } from '@ngrx/store';

const prefix = '[Candidate Linguaskill]';

export const fetchCandidateHasAssessments = createAction(
  `${prefix} Fetch candidate has assessments`,
);
export const setCandidateHasAssessments = createAction(
  `${prefix} Set candidate has assessments`,
  props<{ hasAssessments: boolean }>(),
);
