import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormView } from '@shared/form-view/form-view';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';

@Injectable()
export class FormViewGuard {
  constructor(private genericDialogs: GenericDialogs) {}

  canDeactivate(
    component: FormView,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (component.canDeactivate) {
      return true;
    }
    return this.genericDialogs
      .confirm(component.canDeactivateWarningTitle, component.canDeactivateWarningMessage)
      .pipe(map((value) => !!value));
  }
}
