/**
 * CanidateApi
 * Swiss Exams - Microservices - Candidate Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserExamDocumentDto { 
    id: string;
    name: string;
    type: UserExamDocumentDto.TypeEnum;
    uploadedOn: string;
}
export namespace UserExamDocumentDto {
    export type TypeEnum = 'Invoice' | 'EntryStatement';
    export const TypeEnum = {
        Invoice: 'Invoice' as TypeEnum,
        EntryStatement: 'EntryStatement' as TypeEnum
    };
}


