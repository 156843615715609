/**
 * sx-ms-auth-api
 * Swiss Exams - Microservices - Auth Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FeatureProfilesDto { 
    schoolReservationLinkBoth?: boolean;
    schoolReservationLinkNew?: boolean;
    goetheSchoolReservationLinkBoth?: boolean;
    goetheSchoolReservationLinkNew?: boolean;
    accessCandidateAutomaticReschedule?: boolean;
    examTypeEROTokenSupportList?: Array<FeatureProfilesDto.ExamTypeEROTokenSupportListEnum>;
}
export namespace FeatureProfilesDto {
    export type ExamTypeEROTokenSupportListEnum = 'Cambridge' | 'YLE Exam' | 'Regular Exam' | 'Linguaskill' | 'TCF' | 'Goethe';
    export const ExamTypeEROTokenSupportListEnum = {
        Cambridge: 'Cambridge' as ExamTypeEROTokenSupportListEnum,
        YleExam: 'YLE Exam' as ExamTypeEROTokenSupportListEnum,
        RegularExam: 'Regular Exam' as ExamTypeEROTokenSupportListEnum,
        Linguaskill: 'Linguaskill' as ExamTypeEROTokenSupportListEnum,
        Tcf: 'TCF' as ExamTypeEROTokenSupportListEnum,
        Goethe: 'Goethe' as ExamTypeEROTokenSupportListEnum
    };
}


