import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStateService } from '../../core/auth/auth-state.service';

@Component({
  selector: 'sx-error-view',
  template: `
    <sx-card-layout-view [label]="title" [subTitle]="message" class="dialog">
      <mat-dialog-actions>
        <sx-action-button
          icon="refresh"
          [label]="'Refresh' | translate"
          (action)="onRefresh()"
        ></sx-action-button>
      </mat-dialog-actions>
    </sx-card-layout-view>
  `,
})
export class ErrorViewComponent {
  title: string;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    { title, message }: { title: string; message: string },
    private authstateService: AuthStateService,
  ) {
    this.title = title;
    this.message = message;
  }

  onRefresh() {
    this.authstateService.onLogout();
    window.location.reload();
  }
}
