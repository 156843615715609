import { Injectable } from '@angular/core';
import { AccessTokenDto, UserDto } from '@generated/auth-api';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface AuthState {
  token?: AccessTokenDto;
  user?: UserDto;
}

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private stateSubject = new BehaviorSubject<AuthState>({});
  readonly state$ = this.stateSubject.asObservable();
  readonly token$ = this.state$.pipe(
    map((state) => {
      if (state.token) {
        return state.token.token;
      }
      return '';
    }),
  );

  get state() {
    return this.stateSubject.getValue();
  }

  set state(state: AuthState) {
    this.stateSubject.next(state);
  }

  onTokenAvailable(token: AccessTokenDto) {
    this.state = {
      ...this.state,
      token,
    };
  }

  onLogin(user: UserDto) {
    this.state = {
      ...this.state,
      user,
    };
  }

  onLogout() {
    this.state = {};
  }
}
