import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState, AuthStateService } from './auth/auth-state.service';
import { map } from 'rxjs/operators';
import { UserDto } from '@generated/auth-api';

@Injectable()
export class CanActivatePublicViewGuard {
  constructor(
    private router: Router,
    private authState: AuthStateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const { School, Staff, Candidate } = UserDto.ContextEnum;

    return this.authState.state$.pipe(
      map((state: AuthState) => state.user),
      map((user: UserDto) => {
        if (!user) {
          return true;
        }
        if (route.queryParams.email && route.queryParams.nonce) {
          this.authState.onLogout();
          return true;
        }
        if (user.context === Candidate) {
          return this.router.parseUrl('/candidate');
        } else if (user.context === School) {
          return this.router.parseUrl('/school');
        } else if (user.context === Staff) {
          return this.router.parseUrl('/staff');
        }
        console.warn('Unknown user context: ', user.context);
        return true;
      }),
    );
  }
}
