import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck',
  pure: true,
})
export class PluckPipe implements PipeTransform {
  transform(value: any, host, defaultValue): any {
    return (host && host[value]) || defaultValue;
  }
}
