import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable()
export class CanActivateCommitPasswordResetGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const p = route.queryParams;
    if (!p.email || !p.nonce) {
      // TODO: Maybe show a message?
      return this.router.createUrlTree(['/reset/initiate']);
    }
    return true;
  }
}
