import { Injectable } from '@angular/core';
import {
  GetAssessmentForCandidateDto,
  SaAssessmentGroupDto,
  SkillsAssessmentApi,
} from '@generated/sa-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  fetchCandidateHasAssessments,
  setCandidateHasAssessments,
} from './candidate-linguaskill.actions';
import { catchError, distinctUntilChanged, map, pluck, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AuthStateService } from '../../auth/auth-state.service';
import { UserDto } from '@generated/auth-api';

@Injectable()
export class CandidateLinguaskillEffects {
  fetchActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCandidateHasAssessments),
      switchMap(this.fetchAssessements.bind(this)),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: SkillsAssessmentApi,
    private authStateService: AuthStateService,
    private store: Store,
  ) {
    authStateService.state$
      .pipe(pluck('user'), distinctUntilChanged())
      .subscribe(this.onUserChanged.bind(this));
  }

  onUserChanged(user: UserDto | undefined) {
    if (user && user.isCandidate) {
      this.store.dispatch(fetchCandidateHasAssessments());
    } else {
      this.store.dispatch(setCandidateHasAssessments({ hasAssessments: false }));
    }
  }

  fetchAssessements(): Observable<Action> {
    return this.api.assessmentsForCandidateExists().pipe(
      map((hasAssessments) => setCandidateHasAssessments({ hasAssessments })),
      catchError((err) => {
        // TODO: Error Handling
        console.error(err);
        return of(setCandidateHasAssessments({ hasAssessments: false }));
      }),
    );
  }
}
