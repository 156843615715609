<div *ngFor="let announcement of announcements">
  <div
    *ngIf="canDisplay(announcement)"
    class="announcement-banner"
    [ngClass]="getAdditionalCssClasses(announcement)"
  >
    <mat-icon *ngIf="showIcon(announcement)" aria-hidden="false">{{ announcement.icon }}</mat-icon>
    <ng-container
      *ngTemplateOutlet="
        announcementTemplate;
        context: getLanguageSpecificAnnouncement(announcement)
      "
    ></ng-container>
    <ng-template
      #announcementTemplate
      let-message="message"
      let-link="link"
      let-linkText="linkText"
    >
      <div>
        <span class="announcement-banner__message">
          {{ message }}
        </span>
        <a
          *ngIf="showLink(announcement)"
          class="announcement-banner__link"
          [href]="link"
          target="_blank"
        >
          {{ linkText }}
        </a>
      </div>
    </ng-template>
  </div>
</div>
