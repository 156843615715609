import { ALL_PAYMENT_METHODS, Tenant, TenantIdentifier } from '@shared/tenant/tenant';
import { PaymentDto } from '@generated/school-api/model/paymentDto';

export const IELTS_TENANT: Tenant = {
  identifier: TenantIdentifier.IELTS,
  supportedCountries: ['ch', 'de', 'at', 'fl'],
  bulkRegistrationPaymentMethods: ALL_PAYMENT_METHODS.filter(
    (m) => m !== PaymentDto.MethodEnum.CreditCardPostcard,
  ),
  requiresPlaceOfBirth: true,
  termsAndConditions: [],
  logo: {
    src: '../../../assets/ielts/sx-ielts-800x107.jpg',
    alt: 'IELTS Logo',
  },
};
