import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

if (environment.enableSentry) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.env,
    release: environment.sentryVersion,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['localhost', `${environment.baseUrl}/api`],
    tracesSampleRate: environment.sentryTracesSampleRate,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.None,
  })
  .catch((error) => console.error(error));
