import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'sx-icon-button',
  templateUrl: './icon-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class IconButtonComponent implements OnInit {
  @HostBinding('class') class = 'sx-icon-button';

  @Input() label: string;
  @Input() type = 'button';
  @Input() small = false;
  @Input() icon = 'chevron_right';
  @Input() inverse = false;

  @Output() action = new EventEmitter<any>();

  ngOnInit() {
    if (this.small) {
      this.class += ' small';
    }
    if (this.inverse) {
      this.class += ' inverse';
    }
  }

  onClick(event) {
    this.action.emit(event);
  }
}
