import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from './auth/auth-state.service';
import { map } from 'rxjs/operators';

@Injectable()
export class RequiresContextCanActivateGuard {
  constructor(
    private router: Router,
    private authState: AuthStateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (!route.data.requiredContext) {
      console.warn('RequiresContext guard did not find any required context');
      return true;
    }
    return this.authState.state$.pipe(
      map((state) => state.user),
      map((user) => {
        if (user && user.context === route.data.requiredContext) {
          return true;
        }
        return this.router.createUrlTree(['/']);
      }),
    );
  }
}
