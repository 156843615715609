import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { GenericDialogs } from '@shared/providers/generic-dialogs.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from '@shared/dialogs/loading-dialog.component';

export interface LoadingByKey {
  [key: string]: boolean;
}

function isLoading(byKey: LoadingByKey): boolean {
  return Object.keys(byKey).some((key) => !!byKey[key]);
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly globalLoadingByKeySubject = new BehaviorSubject<LoadingByKey>({});
  readonly globalLoadingByKey$ = this.globalLoadingByKeySubject.asObservable();

  private readonly componentsLoadingByKeySubject = new BehaviorSubject<LoadingByKey>({});
  readonly componentsLoadingByKey$ = this.componentsLoadingByKeySubject.asObservable();

  readonly loadingGlobally$ = this.globalLoadingByKey$.pipe(map(isLoading));

  constructor(private dialogs: GenericDialogs) {}

  isComponentLoading(componentKey: string): Observable<boolean> {
    return this.componentsLoadingByKey$.pipe(map((byKey) => !!byKey[componentKey]));
  }

  start(key: string) {
    this.globalLoadingByKeySubject.next({
      ...this.globalLoadingByKeySubject.getValue(),
      [key]: true,
    });
  }

  stop(key: string) {
    this.globalLoadingByKeySubject.next({
      ...this.globalLoadingByKeySubject.getValue(),
      [key]: undefined,
    });
  }

  startComponent(key: string) {
    this.componentsLoadingByKeySubject.next({
      ...this.componentsLoadingByKeySubject.getValue(),
      [key]: true,
    });
  }

  stopComponent(key: string) {
    this.componentsLoadingByKeySubject.next({
      ...this.componentsLoadingByKeySubject.getValue(),
      [key]: undefined,
    });
  }

  setupLoadingDialog() {
    let ref: MatDialogRef<LoadingDialogComponent>;
    this.loadingGlobally$.pipe(distinctUntilChanged()).subscribe((loading) => {
      if (loading && !ref) {
        ref = this.dialogs.loading();
      } else if (!loading && ref) {
        ref.close();
        ref = null;
      }
    });
  }
}
