/**
 * sx-ms-auth-api
 * Swiss Exams - Microservices - Auth Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationInfoDto } from './organizationInfoDto';


export interface UserDto { 
    username: string;
    email: string;
    fullName: string;
    contactCrmId: string;
    context: UserDto.ContextEnum;
    isCandidate: boolean;
    activeOrg?: OrganizationInfoDto;
    roles?: UserDto.RolesEnum;
    rights?: UserDto.RightsEnum;
}
export namespace UserDto {
    export type ContextEnum = 'candidate' | 'school' | 'staff';
    export const ContextEnum = {
        Candidate: 'candidate' as ContextEnum,
        School: 'school' as ContextEnum,
        Staff: 'staff' as ContextEnum
    };
    export type RolesEnum = 'Operations' | 'Employee' | 'Teacher' | 'SAP' | 'Candidate' | 'StaffUser';
    export const RolesEnum = {
        Operations: 'Operations' as RolesEnum,
        Employee: 'Employee' as RolesEnum,
        Teacher: 'Teacher' as RolesEnum,
        Sap: 'SAP' as RolesEnum,
        Candidate: 'Candidate' as RolesEnum,
        StaffUser: 'StaffUser' as RolesEnum
    };
    export type RightsEnum = 'Access.Candidate.Login' | 'Access.Candidate.SpecialAttachmentDownload' | 'Access.OrgLogin' | 'Access.Teacher.ViewRegistrations' | 'Access.Teacher.ManageReservations' | 'Access.Teacher.BulkRegistration' | 'Access.Org.SAP' | 'Ops.Login' | 'Ops.ViewOpsEvents' | 'Ops.ViewLogs' | 'Ops.InitiateInitialLoad' | 'Ops.EnqueueMessages' | 'Ops.ViewRights' | 'Ops.ViewEntities' | 'Ops.SyncEntity' | 'Employee.Login' | 'Employee.Issues.View' | 'Employee.Issues.Claim' | 'Employee.Issues.Close' | 'Employee.RescheduleRegistrations' | 'StaffUser.Basic.Login' | 'StaffUser.Availability.Read' | 'StaffUser.Availability.Submit' | 'StaffUser.Availability.Revoke' | 'StaffUser.AssignedExams.Read' | 'StaffUser.Payment.Read';
    export const RightsEnum = {
        AccessCandidateLogin: 'Access.Candidate.Login' as RightsEnum,
        AccessCandidateSpecialAttachmentDownload: 'Access.Candidate.SpecialAttachmentDownload' as RightsEnum,
        AccessOrgLogin: 'Access.OrgLogin' as RightsEnum,
        AccessTeacherViewRegistrations: 'Access.Teacher.ViewRegistrations' as RightsEnum,
        AccessTeacherManageReservations: 'Access.Teacher.ManageReservations' as RightsEnum,
        AccessTeacherBulkRegistration: 'Access.Teacher.BulkRegistration' as RightsEnum,
        AccessOrgSap: 'Access.Org.SAP' as RightsEnum,
        OpsLogin: 'Ops.Login' as RightsEnum,
        OpsViewOpsEvents: 'Ops.ViewOpsEvents' as RightsEnum,
        OpsViewLogs: 'Ops.ViewLogs' as RightsEnum,
        OpsInitiateInitialLoad: 'Ops.InitiateInitialLoad' as RightsEnum,
        OpsEnqueueMessages: 'Ops.EnqueueMessages' as RightsEnum,
        OpsViewRights: 'Ops.ViewRights' as RightsEnum,
        OpsViewEntities: 'Ops.ViewEntities' as RightsEnum,
        OpsSyncEntity: 'Ops.SyncEntity' as RightsEnum,
        EmployeeLogin: 'Employee.Login' as RightsEnum,
        EmployeeIssuesView: 'Employee.Issues.View' as RightsEnum,
        EmployeeIssuesClaim: 'Employee.Issues.Claim' as RightsEnum,
        EmployeeIssuesClose: 'Employee.Issues.Close' as RightsEnum,
        EmployeeRescheduleRegistrations: 'Employee.RescheduleRegistrations' as RightsEnum,
        StaffUserBasicLogin: 'StaffUser.Basic.Login' as RightsEnum,
        StaffUserAvailabilityRead: 'StaffUser.Availability.Read' as RightsEnum,
        StaffUserAvailabilitySubmit: 'StaffUser.Availability.Submit' as RightsEnum,
        StaffUserAvailabilityRevoke: 'StaffUser.Availability.Revoke' as RightsEnum,
        StaffUserAssignedExamsRead: 'StaffUser.AssignedExams.Read' as RightsEnum,
        StaffUserPaymentRead: 'StaffUser.Payment.Read' as RightsEnum
    };
}


