import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'sx-loading-dialog',
  template: `
    <div style="text-align: center">
      <mat-spinner></mat-spinner>
    </div>
    <div class="loading-dialog__message">
      {{ 'Please wait' | translate }}
    </div>
  `,
})
export class LoadingDialogComponent {
  @HostBinding('class')
  private cssClass = 'loading-dialog';
}
