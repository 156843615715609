import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CANDIDATE_EXAMS_FEATURE } from './candidate-exams.feature';
import { CandidateExamsState } from './candidate-exams.state';

export const selectCandidateExamsFeature =
  createFeatureSelector<CandidateExamsState>(CANDIDATE_EXAMS_FEATURE);

export const selectCandidateHasExams = createSelector(
  selectCandidateExamsFeature,
  (feature) => !!feature.hasExams,
);
