<div class="public-frame__brand">
  <img
    class="logo img-responsive"
    src="../assets/images/swiss-exams-access-logo.png"
    alt="Swiss Exams"
  />
</div>

<div class="public-frame__language-switch">
  <button mat-button [matMenuTriggerFor]="menu" class="language-switch">
    <mat-icon color="primary">language</mat-icon>
    <span>
      {{ ('Languages.' + formalMapping[translate.currentLang] || translate.currentLang) | translate
      }}
    </span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="onChangeLang(lang)">
      {{ ('Languages.' + lang) | translate }}
    </button>
  </mat-menu>
</div>
<router-outlet></router-outlet>
