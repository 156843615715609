import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as AngularDatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'sxDate',
  pure: true,
})
export class DatePipe implements PipeTransform {
  constructor(private date: AngularDatePipe) {}

  transform(value: any, displayWhenNotAvailable?): any {
    if (!value) {
      return displayWhenNotAvailable || '';
    }

    // Fix time offset of dates to show correct date regardless of current timezone
    const momentDate = moment(value);
    const offset = momentDate.utcOffset();

    return momentDate.utcOffset(offset * -1 + offset + 120).format('DD.MM.YYYY');
  }
}
