import { Directive, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { ResponsiveDirectiveBase } from './responsive-directive-base';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MEDIA_QUERY_DESKTOP } from './media-queries';
import { ResponsiveService } from '../responsive.service';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[isBelowDesktop]',
})
export class IsBelowDesktopDirective extends ResponsiveDirectiveBase {
  constructor(
    element: ElementRef,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    responsive: ResponsiveService,
  ) {
    super(element, templateRef, viewContainer, responsive.isBelowDesktop$);
  }
}
