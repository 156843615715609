import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InformalGuard } from './translation/informal.guard';
import { FormalGuard } from './translation/formal.guard';
import { StaffGuard } from './staff/guards/staff.guard';

const routes: Routes = [
  {
    path: 'candidate',
    canActivate: [InformalGuard],
    canActivateChild: [InformalGuard],
    loadChildren: () => import('./candidate/candidate.module').then((m) => m.CandidateModule),
  },
  {
    path: 'school',
    canActivate: [FormalGuard],
    canActivateChild: [FormalGuard],
    loadChildren: () => import('./school/school.module').then((m) => m.SchoolModule),
  },
  {
    path: 'staff',
    canActivate: [FormalGuard, StaffGuard],
    canActivateChild: [FormalGuard],
    loadChildren: () => import('./staff/staff.module').then((m) => m.StaffModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
