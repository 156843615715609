<mat-card appearance="raised">
  <ng-container *ngIf="error$| async as error; else intro">
    <ng-container [ngSwitch]="!!error">
      <ng-container *ngSwitchCase="!!error?.paid">
        <mat-card-title>{{ "Already paid"|translate }}</mat-card-title>
        <mat-card-subtitle>{{ "Has already been paid" | translate }}</mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content>
          <p>{{error.label}}</p>
          <div *ngIf="error.irn">{{"Reference"| translate}}: {{error.irn}}</div>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="!!error?.canceled">
        <mat-card-title>{{ "Is cancelled"|translate }}</mat-card-title>
        <mat-card-subtitle>{{ "Registration has been cancelled. No payment possible." | translate }}</mat-card-subtitle>
        <mat-divider></mat-divider>
        <mat-card-content>
          <p>{{error.label}}</p>
          <div *ngIf="error.irn">{{"Reference"| translate}}: {{error.irn}}</div>
        </mat-card-content>
      </ng-container>
      <ng-container *ngSwitchCase="!!error?.sessionActive">
        <mat-card-title>{{ "Active Session"| translate }}</mat-card-title>
        <mat-card-subtitle>
          {{ "Payment is already ongoing. Please try again later." | translate }}
        </mat-card-subtitle>
      </ng-container>
      <ng-template *ngSwitchDefault>
        <mat-card-title>{{"Error Title" | translate}}</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <div>{{"Unexpected Error Message" | translate}}</div>
        </mat-card-content>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #intro>
    <mat-card-content>
      <div class="intro">
        <div>
          {{ "Payment successfully initialised. Click to start the payment process." | translate }}
        </div>
        <div class="intro__button-section">
          <sx-action-button
            (action)="onStartPaymentClick()"
            [label]="'Pay now' | translate"
          ></sx-action-button>
        </div>
      </div>
    </mat-card-content>
  </ng-template>
</mat-card>
