import { SubTypeDto } from '@generated/school-api';
import { Tenant } from '@shared/tenant/tenant';
import { GOETHE_TENANT } from '@shared/tenant/goethe.tenant';
import { CAMBRIDGE_TENANT } from '@shared/tenant/cambridge.tenant';
import { IELTS_TENANT } from '@shared/tenant/ielts.tenant';

export function getTenantForSubType(subType: SubTypeDto): Tenant {
  if (subType && subType.goetheExamTypeCrmId) {
    return GOETHE_TENANT;
  } else {
    return CAMBRIDGE_TENANT;
  }
}

export const TENANTS = [GOETHE_TENANT, CAMBRIDGE_TENANT, IELTS_TENANT];
