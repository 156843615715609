import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@shared/providers/validation.service';

export interface ExternalLinkDialogData {
  title: string;
  message?: string;
  url: string;
}

export interface ExternalLinkDialogResult {
  comment: string;
}

@Component({
  selector: 'sx-external-link-dialog',
  template: `
    <sx-card-layout-view class="dialog" [label]="data.title | translate" style="max-width: 500px">
      <div mat-dialog-content>
        <p *ngIf="data.message" [innerHTML]="data.message | translate"></p>
      </div>
      <div>
        <sx-link-button
          [url]="data.url"
          [isExternalLink]="true"
          [label]="'Visit External Site' | translate"
        ></sx-link-button>
      </div>
      <mat-divider class="dialog__divider"></mat-divider>
      <div class="dialog__actions">
        <button mat-button (click)="onOK()">{{ 'OK' | translate }}</button>
      </div>
    </sx-card-layout-view>
  `,
})
export class ExternalLinkDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ExternalLinkDialogData,
    private dialogRef: MatDialogRef<ExternalLinkDialogComponent>,
  ) {}

  onOK() {
    this.dialogRef.close();
  }
}
