import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sx-fail-payment-view',
  templateUrl: './fail-payment.view.html',
})
export class FailPaymentViewComponent implements OnInit {
  label: string;
  irn: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.irn = this.route.snapshot.queryParamMap.get('irn');
    this.label = this.route.snapshot.queryParamMap.get('label');
  }
}
