import { Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { passwordMatches } from '../../util/validators/passwords-match';
import { TranslateService } from '@ngx-translate/core';
import { validationErrorDetails } from '../../util/validation/validation-error-details';
import { ValidationService } from '@shared/providers/validation.service';
import { ChangePasswordFacade } from '@shared/change-password/change-password.facade';
import { traverseControl } from '../../util/traverse-control';
import { NotificationService } from '@shared/providers/notification.service';

@Component({
  selector: 'sx-change-password-view',
  template: `
    <sx-key-value [key]="'Change Password' | translate" [alignTop]="true">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" #formElement>
        <div>
          <mat-form-field>
            <mat-label>{{ 'Password' | translate }}</mat-label>
            <input #passwordField matInput type="password" formControlName="currentPassword" />
            <mat-hint>{{ 'Your current password.' | translate }}</mat-hint>
            <mat-error *ngIf="validate('currentPassword', 'Current Password') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ 'New Password' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPassword" />
            <mat-hint>{{ 'Enter your new password.' | translate }}</mat-hint>
            <mat-error *ngIf="validate('newPassword', 'New Password') as details">
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label>{{ 'Confirm Password' | translate }}</mat-label>
            <input matInput type="password" formControlName="newPasswordConfirmation" />
            <mat-hint>{{ 'Both passwords need to match.' | translate }}</mat-hint>
            <mat-error
              *ngIf="validate('newPasswordConfirmation', 'Password Confirmation') as details"
            >
              {{ details.message | translate: details.params }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="margin-top">
          <sx-action-button
            [label]="'Set New Password' | translate"
            [type]="'submit'"
            ngClass="public-card__action-button"
          ></sx-action-button>
        </div>
      </form>
    </sx-key-value>
  `,
  styleUrls: ['change-password.view.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordViewComponent {
  @HostBinding('class')
  private cssClass = 'change-password';

  @ViewChild(FormGroupDirective, { static: true })
  formElement;

  form = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', [Validators.required]),
    newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
    newPasswordConfirmation: new UntypedFormControl('', [
      Validators.required,
      passwordMatches(() => this.form && this.form.get('newPassword')),
    ]),
  });

  validate = validationErrorDetails(this.form, this.translate);

  constructor(
    private translate: TranslateService,
    private validation: ValidationService,
    private facade: ChangePasswordFacade,
    private notification: NotificationService,
  ) {}

  onSubmit() {
    this.validation.validateAndThen(this.form, (value) =>
      this.facade.changePassword(value).subscribe(
        () => {
          this.notification.success('Your password has been changed successfully.');
          this.form.patchValue({
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
          });
          traverseControl(this.form, (control) => {
            control.reset();
            control.markAsPristine();
            control.markAsUntouched();
          });
          this.formElement.resetForm();
        },
        // TODO: Display more specific message
        (error) => this.notification.error(error),
      ),
    );
  }
}
