import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_PHONE,
  MEDIA_QUERY_TABLET,
} from './directives/media-queries';
import { distinctUntilChanged, map, share, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  readonly isPhone$ = this.breakpointObserver.observe(MEDIA_QUERY_PHONE).pipe(
    map((state: BreakpointState) => state.matches),
    distinctUntilChanged(),
    shareReplay(),
  );

  readonly isTablet$ = this.breakpointObserver.observe(MEDIA_QUERY_TABLET).pipe(
    map((state: BreakpointState) => state.matches),
    distinctUntilChanged(),
    shareReplay(),
  );

  readonly isDesktop$ = this.breakpointObserver.observe(MEDIA_QUERY_DESKTOP).pipe(
    map((state: BreakpointState) => state.matches),
    distinctUntilChanged(),
    shareReplay(),
  );

  readonly isBelowDesktop$ = this.isDesktop$.pipe(
    map((value) => !value),
    distinctUntilChanged(),
    shareReplay(),
  );

  readonly isAbovePhone$ = this.isPhone$.pipe(
    map((value) => !value),
    distinctUntilChanged(),
    share(),
  );

  constructor(private breakpointObserver: BreakpointObserver) {}
}
