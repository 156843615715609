<div class="payment-frame">
  <div class="payment-frame__brand">
    <img
      class="logo img-responsive"
      src="../assets/images/swiss-exams-access-logo.png"
      alt="Swiss Exams"
    />
  </div>

  <router-outlet></router-outlet>
</div>
