import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith } from 'rxjs/operators';

interface ValidationMessage {
  key: string;
  value: string;
}

@Component({
  selector: 'sx-validation-messages',
  template: `
    <div *ngFor="let message of messages$ | async" [ngClass]="messageClass">
      {{
        'validation.' + message.key
          | translate
            : { value: message.value, translated: (message.value | toStringIfNone | translate) }
      }}
    </div>
  `,
  styleUrls: ['validation-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValidationMessagesComponent {
  messages$: Observable<ValidationMessage[]>;
  touched = false;
  private touched$ = new Subject<any>();

  messageClass = 'validation-message';

  @Input()
  set omitMargin(omit: boolean) {
    this.messageClass = omit
      ? 'validation-message validation-message--margin-less'
      : 'validation-message';
  }

  constructor(private translate: TranslateService) {}

  setTouched(touched: boolean) {
    this.touched = touched;
    this.touched$.next(touched);
  }

  @Input()
  set control(control: AbstractControl) {
    control.statusChanges
      .pipe(
        map(() => control.touched),
        distinctUntilChanged(),
      )
      .subscribe((isTouched) => this.setTouched(isTouched));

    this.messages$ = merge(this.touched$, control.valueChanges).pipe(
      // Delaying so the touched state has time to change
      startWith({}),
      delay(10),
      map(() => {
        return control.touched ? control.errors : {};
      }),
      map((errors: ValidationErrors) =>
        Object.keys(errors || {}).map((key) => {
          let value = errors[key];
          if (typeof value === 'boolean') {
            value = '' + value;
          }

          return { key, value };
        }),
      ),
    );
  }
}
