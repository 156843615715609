import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
}

@Injectable()
export class ChangePasswordFacade {
  changePassword: (payload: ChangePasswordPayload) => Observable<any>;
}
