/**
 * sx-ms-school-api
 * Swiss Exams - Microservices - school Api
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SchoolReservationDto { 
    id: string;
    crmId: string;
    name: string;
    examType: string;
    examSubType?: string;
    date: string;
    location: string;
    isComplete: boolean;
    learningMaterialLink: string;
    registrationLink: string;
    tenant: SchoolReservationDto.TenantEnum;
}
export namespace SchoolReservationDto {
    export type TenantEnum = 'Cambridge' | 'Goethe';
    export const TenantEnum = {
        Cambridge: 'Cambridge' as TenantEnum,
        Goethe: 'Goethe' as TenantEnum
    };
}


