import { Component, Input } from '@angular/core';

@Component({
  selector: 'sx-loading-spinner',
  template: `
    <span class="loading-spinner loading-spinner--{{ color }}">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  `,
})
export class LoadingSpinnerComponent {
  @Input() color: 'primary' | 'white' = 'primary';
}
