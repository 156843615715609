import { createReducer, on } from '@ngrx/store';
import { DEFAULT_CANDIDATE_LINGUA_SKILL_STATE } from './candidate-linguaskill.state';
import { setCandidateHasAssessments } from './candidate-linguaskill.actions';

export const candidateLinguaskillReducer = createReducer(
  DEFAULT_CANDIDATE_LINGUA_SKILL_STATE,
  on(setCandidateHasAssessments, (state, { hasAssessments }) => ({
    ...state,
    hasAssessments,
  })),
);
