import { Destroyable } from '../../../util/destroyable';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class ResponsiveDirectiveBase extends Destroyable {
  protected constructor(
    protected element: ElementRef,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    matches$: Observable<boolean>,
  ) {
    super();
    matches$.pipe(distinctUntilChanged(), this.takeUntilDestroyed()).subscribe((state) => {
      if (state) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  hide() {
    this.viewContainer.clear();
  }
}
