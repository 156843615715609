import { Injectable } from '@angular/core';
import { InitalPaymentResponseDto, PaymentApi } from '@generated/payment-api';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  constructor(private paymentApi: PaymentApi) {}

  public init(tenant: string, invoiceCrmId: string, contactCrmId: string): Observable<any> {
    // NOTE: in order to transit from the old way of pay now features we need to have nulled contact
    let initialPaymentResponse: Observable<InitalPaymentResponseDto>;

    if (!contactCrmId) {
      // NOTE: set the last argument to true to bypass the service worker
      initialPaymentResponse = this.paymentApi.initLegacyPayment(tenant, invoiceCrmId, 'true');
    } else {
      // NOTE: set the last argument to true to bypass the service worker
      initialPaymentResponse = this.paymentApi.initPayment(
        tenant,
        contactCrmId,
        invoiceCrmId,
        'true',
      );
    }

    return initialPaymentResponse.pipe(
      tap((response) => this.redirect(response)),
      catchError((error) => {
        this.onInitFailed(error);
        throw error;
      }),
    );
  }

  // Send the redirect url to go to
  private redirect(response: { RedirectUrl: string }) {
    window.open(response.RedirectUrl, '_self');
  }

  private onInitFailed(error: any) {
    console.error(error);
  }
}
